import { CircularProgress, Grid, SxProps, Typography } from '@mui/material';
import React from 'react';


type LoadingArgs = {
    message?: string;
    fillViewport?: boolean;
    sx?: SxProps;
}


const Loading: React.FC<LoadingArgs> = ({ message, fillViewport = false, sx }) => {

    return (
        <Grid container direction="row" width='100%'
            height={fillViewport ? '100vh' : '100%'}
            alignContent='center' textAlign='center' sx={sx}>
            <Grid item xs />
            <Grid item>
                <CircularProgress />
                <Typography variant='h6'>
                    {message ?? '--LOADING--'}
                </Typography>
            </Grid>
            <Grid item>
            </Grid>
            <Grid item xs />
        </Grid>
    );
};

export default Loading;
