import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

const DownloadPage = () => {

    useEffect(() => {
        if (isAndroid) {
            window.location.href =
                'https://play.google.com/store/apps/details?id=com.fuocos.pizza';
        } else if (isIOS) {
            window.location.href =
                'https://apps.apple.com/app/fuocos-pizza/id1488824309';
        } else {
            window.location.href =
                '/';
        }
    }, []);

    return (
        <div />
    );
};

export default DownloadPage;
