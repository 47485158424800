import { LinearProgress, Typography, Button, Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRemoteConfigString } from 'reactfire';
import { loadStripe, Stripe, StripePaymentElementChangeEvent, PaymentIntent } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';

type StripePaymentElementArgs = {
    onPayment: (paymentIntent: PaymentIntent) => void;
};

type StripePayArgs = StripePaymentElementArgs & {
    paymentIntent: string;
};

const StripePay: React.FC<StripePayArgs> = ({ paymentIntent, onPayment }) => {

    const stripePublishableKey = useRemoteConfigString('stripe_publishable_key');
    const [stripeObject, setStripeObject] = useState<Stripe | null>(null);

    /** Load Stripe Object (only once) */
    useEffect(() => {

        if (stripePublishableKey.data) {
            loadStripe(stripePublishableKey.data).then(response => setStripeObject(response));
        }

    }, [stripePublishableKey.data]);

    if (stripePublishableKey.status === 'loading') return <LinearProgress />;
    if (stripePublishableKey.status === 'error') return <Typography>{stripePublishableKey.error}</Typography>;
    if (stripeObject === null) return <Typography>Loading Stripe...</Typography>;

    return (
        <Elements stripe={stripeObject} options={{ clientSecret: paymentIntent }}>
            <StripePaymentElement onPayment={onPayment} />
        </Elements>
    );

};

const StripePaymentElement: React.FC<StripePaymentElementArgs> = ({ onPayment }) => {

    const stripe = useStripe();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    if (stripe === null) throw new Error('Stripe not available');
    if (elements === null) throw new Error('Elements not availble');

    const handlePaymentElementChange = (ev: StripePaymentElementChangeEvent) => {
        console.debug('handlePaymentElementChange', ev);
    };

    const handlePaymentElementSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {

        ev.preventDefault();

        console.debug('handlePaymentElementSubmit', ev);

        try {

            const response = await stripe.confirmPayment({ elements, redirect: 'if_required' });
            console.debug(response);

            if (response.error) throw new Error(response.error.message);

            onPayment(response.paymentIntent);

        } catch (error) {
            enqueueSnackbar((error as Error).message, { variant: 'error' });
        }

    };

    return (
        <Box component={'form'} marginBottom={8} margin={4} onSubmit={handlePaymentElementSubmit}>
            <PaymentElement onChange={handlePaymentElementChange} />
            <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.neutral.main,
                    marginTop: 2
                }}>
                Submit Payment
            </Button>
        </Box>
    );

};


export default StripePay;
