import React, { useState } from 'react';
import { useFirebaseConfig } from '../hooks/use-firebase-config';
import { FirebaseAppProvider } from 'reactfire';
import { AppRoutes } from './router';
import FirebaseComponents from '../config/firebase-components';
import { kioskLightTheme, kioskDarkTheme } from '@hcstechnologies/fuocos-themes';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider, useMediaQuery, LinearProgress } from '@mui/material';

export const NoSelectedRestaurant = React.createContext<any>(undefined);

function App() {

    const [noSelectedRestaurant, setNoSelectedRestaurant] = useState(false);
    const { config: firebaseConfig } = useFirebaseConfig();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Load all app dependencies before loading main app
    if (firebaseConfig === undefined) return <LinearProgress />;

    return (
        <ThemeProvider theme={prefersDarkMode ? kioskDarkTheme : kioskLightTheme}>
            <CssBaseline>
                <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                    <FirebaseComponents>
                        <SnackbarProvider>
                            <NoSelectedRestaurant.Provider value={{ noSelectedRestaurant, setNoSelectedRestaurant }}>
                                <AppRoutes />
                            </NoSelectedRestaurant.Provider>
                        </SnackbarProvider>
                    </FirebaseComponents>
                </FirebaseAppProvider>
            </CssBaseline>
        </ThemeProvider>
    );
}

export default App;
