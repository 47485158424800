import { useState } from 'react';
import axios from 'axios';
import {
    TPostcodesByLatLngResponse, TLatLng,
    TInRangeResponse, TGetAddressIoResponse
} from '@hcstechnologies/fuocos-interfaces/src/types';
import { useFunctions, useRemoteConfig } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { getString } from 'firebase/remote-config';
import { getAnalytics, logEvent } from 'firebase/analytics';


/**
 * Location Services
 * @returns
 */
const useLocation = () => {

    const [currentLocation, setCurrentLocation] = useState<GeolocationPosition>();
    const functions = useFunctions();
    const remoteConfig = useRemoteConfig();
    const analytics = getAnalytics();


    /**
     * Get current location, also sets the currentLocation
     */
    const updateCurrentLocation = async (): Promise<GeolocationPosition> => {

        if ('geolocation' in navigator === false) throw new Error('geolocation is unavailable');

        return new Promise((resolve, reject) => {

            logEvent(analytics, 'get_position');

            navigator.geolocation.getCurrentPosition((location: GeolocationPosition) => {
                console.log('location', location);
                setCurrentLocation(location);
                resolve(location);
            }, (error: GeolocationPositionError) => {
                console.error(error);
                reject(error.message);
            });

        });

    };

    /**
     * Get postcodes near current location
     */
    const getPostcodeForCurrentLocation = async (): Promise<TPostcodesByLatLngResponse> => {

        const { coords } = await updateCurrentLocation();

        const postcodeServiceUrl = `https://api.postcodes.io/postcodes?lon=${coords.longitude}&lat=${coords.latitude}`;

        logEvent(analytics, 'get_areas_for_lat_long');
        const { data } = await axios.get<TPostcodesByLatLngResponse>(postcodeServiceUrl);

        return data;

    };

    /** Use current location to get nearest restaurants */
    const getRestaurantsForCurrentLocation = async (): Promise<TInRangeResponse[]> => {

        const { coords } = await updateCurrentLocation();
        const destination: TLatLng = [coords.latitude, coords.longitude];
        const getInRangeLocations = httpsCallable<TLatLng, TInRangeResponse[]>(functions, 'getInRangeLocations');

        logEvent(analytics, 'get_in_range_locations');
        const { data } = await getInRangeLocations(destination);


        return data;

    };

    /** Use latlong or a postcode string to get nearest restaurants */
    const getRestaurantsForLocation = async (destination: TLatLng | string): Promise<TInRangeResponse[]> => {
        const getInRangeLocations = httpsCallable<TLatLng | string, TInRangeResponse[]>(functions, 'getInRangeLocations');

        logEvent(analytics, 'get_in_range_locations');
        const { data } = await getInRangeLocations(destination);

        console.debug('getRestaurantsForLocation', data);

        return data;
    };

    /** Display addresses for selected postcode */
    const getAddressesForPostcode = async (postcode: string): Promise<TGetAddressIoResponse> => {

        const GET_ADDRESS_API_KEY = getString(remoteConfig, 'get_address_api_key');
        const url = `https://api.getAddress.io/find/${postcode}?api-key=${GET_ADDRESS_API_KEY}&expand=true&sort=true`;

        logEvent(analytics, 'get_addresses_for_postcode');
        const { data } = await axios.get<TGetAddressIoResponse>(url);

        console.log({ postcode, url, data });

        return data;
    };

    return {
        currentLocation, updateCurrentLocation,
        getPostcodeForCurrentLocation, getRestaurantsForCurrentLocation,
        getAddressesForPostcode,
        getRestaurantsForLocation
    };

};


export default useLocation;
