import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import { AppBar, Drawer, Toolbar, Stack, Button, Box, Grid, Badge, useTheme, useMediaQuery } from '@mui/material';
import { ShoppingBasket, Menu } from '@mui/icons-material';
import { SelectRestaurant } from '../components/select-restaurant';
import BasketDialog from '../components/basket-dialog';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { NoSelectedRestaurant } from '../routes/app';
import { useBasket } from '../hooks/use-basket';
import FuocosLogo from '../assets/FuocosLogo.png';

type THomePageArgs = {
    handleGetNavBarHeight: (navBarHeight: number) => void
};

const HomePage: React.FC<THomePageArgs> = ({ handleGetNavBarHeight }) => {

    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);
    // anchorEl is used to set the position of the popover for the basket
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [showBasket, setShowBasket] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const basket = useBasket();
    const { setNoSelectedRestaurant } = useContext(NoSelectedRestaurant);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navBarRef = useRef<HTMLDivElement>(null);

    /**
     * If the user has selected a location,
     * Navigate to checkout page when user clicks checkout on basket.
     * Otherwise, set the select restaurant dialog
     */
    const handleCheckout = () => {
        location === null ? setNoSelectedRestaurant(true) : navigate('/checkout');
        setDialogOpen(false);
        setDrawerOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setDialogOpen(true);
    };

    const handleShowBasket = () => {
        setShowBasket(true);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const MenuList = () => {
        return (
            <Fragment>
                <Button color='inherit' onClick={() => navigate('/')}>
                    Menu
                </Button>

                <Button color='inherit' onClick={() => navigate('/orders')}>
                    Orders
                </Button>
            </Fragment>
        );
    };

    /**
     * We want to get the height of the nav bar whenever it changes height so that other components can
     * style themselves according to the value
     */
    useEffect(() => {
        if (navBarRef.current) handleGetNavBarHeight(navBarRef.current.clientHeight);
    }, [navBarRef.current?.clientHeight]);

    return (

        <Grid container direction='column' height='100vh'>

            <AppBar color='primary' position='sticky' ref={navBarRef} elevation={0}
                sx={{
                    zIndex: theme.zIndex.drawer + 1
                }}>
                <Toolbar>

                    <Link to={'/'}>
                        <img src={FuocosLogo} width={150} height={'auto'} />
                    </Link>

                    {!isSmall && MenuList()}

                    <Box flexGrow={1} />

                    <SelectRestaurant handleShowBasket={handleShowBasket} />

                    {showBasket &&
                        <Button onClick={handleClick} color='inherit'>
                            <Badge badgeContent={basket.basketDoc.data?.items.length} color='secondary'>
                                <ShoppingBasket />
                            </Badge>
                        </Button>
                    }

                    {isSmall &&
                        <Fragment>
                            <Button onClick={toggleDrawer} color='inherit'
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    alignSelf: 'flex-start',
                                }}>
                                <Menu />
                            </Button>
                            <Drawer
                                anchor='top'
                                open={drawerOpen}
                                onClick={toggleDrawer}
                                sx={{
                                    '.MuiDrawer-paper': {
                                        top: navBarRef.current?.clientHeight,
                                        minHeight: '10vh'
                                    }
                                }}>
                                <Stack height='100%'>
                                    {MenuList()}
                                </Stack>
                            </Drawer>
                        </Fragment>
                    }

                    {dialogOpen &&
                        <BasketDialog
                            open={dialogOpen}
                            handleClose={() => setDialogOpen(false)}
                            anchorEl={anchorEl}
                            onCheckout={handleCheckout}
                        />
                    }

                </Toolbar>
            </AppBar>

            <Grid item xs overflow={'scroll'}>
                <Outlet />
            </Grid>

        </Grid>

    );
};

export default HomePage;
