import React, { useState } from 'react';
import Loading from './loading';
import {
    TValidatedBasketItem, TValidateBasketRequestItem
} from '@hcstechnologies/fuocos-interfaces/src/types';
import { useBasket } from '../hooks/use-basket';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { MonoTypography, FuocosCard } from '@hcstechnologies/fuocos-themes';
import { Close, RemoveCircle, AddCircle, Add, Remove, ShoppingBasket } from '@mui/icons-material';
import {
    Typography, Popover, List, Button, Stack, Box, Grid,
    IconButton, useTheme, Divider, ListItem, LinearProgress, ButtonBase, useMediaQuery
} from '@mui/material';
import { assertNotNull } from '../shared/utils';
import { normaliseError } from '../shared/utils';

type TBasketDialogArgs = {
    open: boolean;
    handleClose: (event?: unknown, reason?: 'escapeKeyDown' | 'backdropClick' | undefined) => void;
    anchorEl: HTMLButtonElement | null;
    onCheckout: () => void;

};

const BasketDialog: React.FC<TBasketDialogArgs> = ({ open, handleClose, anchorEl, onCheckout }) => {
    const { clearBasket } = useBasket();
    const basket = useBasket();
    const [busy, setBusy] = useState<boolean>(false);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    if (basket.basketDoc.status === 'loading') return <Loading />;

    if ([basket.basketDoc.status].includes('error')) return (
        <Typography variant='h1'>
            {basket.basketDoc.error?.message}
        </Typography>
    );

    const handleItemQtyChange = async (
        item: TValidatedBasketItem, index: number,
        array: TValidateBasketRequestItem[], delta: number): Promise<void> => {

        console.debug('handleItemQtyChange', item, index, array);

        try {

            setBusy(true);

            assertNotNull(basket);

            const response = await basket.updateItemQty(item, index, array, delta);

            console.debug(response);

        } catch (error) {
            console.error(error);
        }

        setBusy(false);

    };

    const handleClearBasket = async () => {

        try {
            setBusy(true);
            pathname === '/checkout' ? navigate('/') : null;
            await clearBasket();
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
                enqueueSnackbar(normaliseError(error).message, { variant: 'error' });
            }
        }

        setBusy(false);
    };

    return (
        <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            PaperProps={{
                sx: {
                    display: 'flex', flexDirection: 'column',
                    justifyContent: basket.basketDoc.data === undefined ? 'center' : 'flex-start',
                    width: isMedium ? '50vh' : '30vw', minHeight: '30vh', maxHeight: '75vh'
                }
            }}>

            <ButtonBase onClick={() => handleClose()}
                sx={{
                    display: 'inline-block', position: 'absolute', top: 0, left: 4, zIndex: 1, p: 1
                }}>
                <Close fontSize="large" />
            </ButtonBase>

            {basket.basketDoc.data === undefined &&
                <Stack alignItems='center'>
                    <Typography variant='h6' textAlign='center'>
                        No Items In Basket
                    </Typography>
                    <ShoppingBasket />
                </Stack>
            }

            <Grid container direction="column" height='100%' >

                {basket.basketDoc.data &&

                    <React.Fragment>

                        <Grid item xs>
                            <List
                                sx={{
                                    display: 'flex', flexDirection: 'column',
                                    alignContent: 'center', marginTop: theme.spacing(6),
                                }}>
                                {basket.basketDoc?.data.validatedBasket.items.map((item, index, array) => (
                                    <BasketItem
                                        key={index} item={item}
                                        handleChangeQty={delta => handleItemQtyChange(item, index, array, delta)} />
                                ))}
                            </List>
                        </Grid>


                        <Divider variant="middle" />

                        <Grid item xs position={'sticky'} bottom={0}
                            sx={{ backgroundColor: theme.palette.background.paper }}>
                            <List>
                                <ListItem>
                                    <Stack direction={'row'} width={'100%'}>
                                        <MonoTypography>
                                            Total
                                        </MonoTypography>

                                        <Box
                                            flexGrow={1}
                                            sx={{
                                                borderBottomWidth: '1px',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: theme.palette.grey.A400,
                                                marginRight: theme.spacing(1),
                                                marginLeft: theme.spacing(1),
                                            }}
                                        />

                                        <MonoTypography>
                                            £{basket.basketDoc.data.validatedBasket.totalAmount.toFixed(2)}
                                        </MonoTypography>
                                    </Stack>
                                </ListItem>

                                <ListItem>
                                    <Stack direction={'row'} width={'100%'}>
                                        <MonoTypography>
                                            Includes VAT
                                        </MonoTypography>

                                        <Box
                                            flexGrow={1}
                                            sx={{
                                                borderBottomWidth: '1px',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: theme.palette.grey.A400,
                                                marginRight: theme.spacing(1),
                                                marginLeft: theme.spacing(1),
                                            }}
                                        />

                                        <MonoTypography>
                                            £{basket.basketDoc.data.validatedBasket.totalTax.toFixed(2)}
                                        </MonoTypography>
                                    </Stack>
                                </ListItem>

                                <Stack direction={'column'} spacing={1} padding={1} mb={0}>

                                    <Button fullWidth disabled={busy}
                                        onClick={() => onCheckout()} variant='contained'
                                        sx={{
                                            height: '4.5rem',
                                        }}>
                                        Checkout
                                    </Button>

                                    <Button fullWidth disabled={busy} onClick={() => handleClearBasket()} variant='outlined' color='warning'>
                                        Clear Basket


                                    </Button>

                                </Stack>

                            </List>
                        </Grid>
                        {busy &&
                            <LinearProgress variant="indeterminate" />}

                    </React.Fragment>

                }

            </Grid>

        </Popover>
    );
};

type TBasketItemArgs = {
    item: TValidatedBasketItem;
    handleChangeQty: (delta: number) => void;
}

/**
 * Display item details
 */
function BasketItem({ item, handleChangeQty }: TBasketItemArgs) {

    const theme = useTheme();

    return (

        <React.Fragment>

            <FuocosCard
                style={{
                    margin: theme.spacing(2),
                    marginTop: 0,
                    padding: theme.spacing(2)
                }}>

                <Grid container alignItems='space-between'>

                    <Grid item container xs={6} direction='column'>

                        <Typography fontWeight='bold'>
                            {item.productName}
                        </Typography>

                        <Grid item marginTop={theme.spacing(1)}>

                            {item.extras.map((extra, index) => (
                                <Stack direction='row' key={index} color={theme.palette.text.secondary} alignItems='center'>

                                    <Add fontSize='small' />
                                    <MonoTypography fontWeight='bold' fontSize={theme.spacing(1.5)}>
                                        {extra.productName} £{(extra.unitPrice * extra.quantity).toFixed(2)}
                                    </MonoTypography>

                                </Stack>
                            ))}

                            {item.remove?.map((remove, index) => (
                                <Stack direction='row' key={index} color={theme.palette.text.secondary} alignItems='center'>

                                    <Remove fontSize='small' />
                                    <MonoTypography fontWeight='bold' fontSize={theme.spacing(1.5)}>
                                        {remove.name}</MonoTypography>

                                </Stack>
                            ))}


                        </Grid>

                    </Grid>


                    <Grid item container xs={6} direction='column' alignItems='center' alignContent='flex-end'>


                        <Typography fontWeight='bold'>
                            £{(item.unitPrice * item.quantity).toFixed(2)}
                        </Typography>


                        <Stack direction='row' alignItems='center'>
                            <IconButton onClick={() => handleChangeQty(-1)}>
                                <RemoveCircle sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                            <Typography fontWeight='bold'>
                                {item.quantity}
                            </Typography>
                            <IconButton onClick={() => handleChangeQty(1)}>
                                <AddCircle sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Stack>
                    </Grid>

                </Grid>

            </FuocosCard>


        </React.Fragment>

    );

}

export default BasketDialog;
