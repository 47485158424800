import React, { useState, useEffect, useContext, Fragment } from 'react';
import useLocation from '../hooks/use-location';
import { TPostcodesByLatLngResponse, TInRangeResponse } from '@hcstechnologies/fuocos-interfaces/src/types';
import { Button } from '@mui/material';
import { LocationSearching } from '@mui/icons-material';
import SelectRestaurantDialog from './select-restaurant-dialog';
import { usePostcodeContext, useLocationContext } from '../contexts/LocationContext';
import { NoSelectedRestaurant } from '../routes/app';

type TSelectRestaurantArgs = {
    handleShowBasket?: () => void;

};

export const SelectRestaurant: React.FC<TSelectRestaurantArgs> = ({ handleShowBasket }) => {

    const { setPostcode } = usePostcodeContext();
    const { setLocation } = useLocationContext();
    const locationServices = useLocation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState<TInRangeResponse | null>(null);
    const [status, setStatus] = useState('Searching for Restaurants...');
    const { noSelectedRestaurant, setNoSelectedRestaurant } = useContext(NoSelectedRestaurant);

    useEffect(() => {

        locationServices.getPostcodeForCurrentLocation()
            .then((postcode) => handleInitialPostcodeCheck(postcode))
            .catch(() => setStatus('Select Restaurant...'));

        locationServices.getRestaurantsForCurrentLocation()
            .then(handleInitialRestaurantCheck)
            .catch(() => setStatus('Select Restaurant...'));

    }, []);

    /* On component update, set the postcode locally if one exists */
    const handleInitialPostcodeCheck = (postcode: TPostcodesByLatLngResponse): void => {
        setPostcode(postcode.result[0].postcode);
        console.debug(`Postcode: ${postcode.result[0].postcode}`);
    };

    /* On component update, search for whether a single restaurant exists for the users location & set it if so */
    const handleInitialRestaurantCheck = (locations: TInRangeResponse[]) => {

        // If there is only one restaurant, select it
        if (locations.length === 1) {
            handleSetRestaurant(locations[0], locations[0].location.postcode ?? '');
        }

        if (locations.length === 0) {
            setSelectedRestaurant(null);
            setStatus('No Restaurants Found');
            setLocation(null);
        }

    };

    /* Set selected restaurant */
    const handleSetRestaurant = (location: TInRangeResponse, postcode: string) => {
        setSelectedRestaurant(location);
        setPostcode(postcode);
        setStatus(location.location.name);
        setLocation(location);
        handleShowBasket?.();
        console.debug(selectedRestaurant);
    };

    const close = () => {
        setDialogOpen(false);
        setNoSelectedRestaurant(false);
    };

    return (
        <Fragment>
            <Button color='inherit' onClick={() => setDialogOpen(true)}>
                <LocationSearching sx={{ mr: 1 }} />
                {status}
            </Button>

            <SelectRestaurantDialog
                open={dialogOpen || noSelectedRestaurant}
                handleClose={() => close()}
                handleRestaurantSelect={
                    (restaurant, currentPostcode) => {
                        handleSetRestaurant(restaurant, currentPostcode);
                        setDialogOpen(false);
                        close();
                    }} />

        </Fragment>
    );

};
