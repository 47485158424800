import React from 'react';
import { DateTime, DateTimeFormatOptions } from 'luxon';

type FormatDateArgs = {
    date: Date | string;
    format?: DateTimeFormatOptions;
};

/** Display a date in {format} */
const FormatDate: React.FC<FormatDateArgs> = ({ date }) => {

    const dateTime = typeof date === 'string' ? DateTime.fromISO(date).toFormat('h:mm a') : DateTime.fromJSDate(date).toFormat('h:mm a');

    return (
        <React.Fragment>
            {dateTime.toLocaleString()}
        </React.Fragment>
    );

};

export default FormatDate;
