import React from 'react';
import { TMenuProduct } from '@hcstechnologies/fuocos-interfaces/src/types';
import { CardMedia, Typography, CardContent, Button, CardActions, Slide, Box, useTheme, useMediaQuery } from '@mui/material';
import { MonoTypography, FuocosCard, } from '@hcstechnologies/fuocos-themes';

export type TMenuProductArgs = {
    product: TMenuProduct;
    onAddToBasket: (
        ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        product: TMenuProduct
    ) => void;
};

export const MenuProduct: React.FC<TMenuProductArgs> = ({ product, onAddToBasket }) => {

    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Slide in={true} direction={'down'} timeout={350}>
            <FuocosCard
                elevation={0}
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    borderRadius: 0,
                }}>


                {product.imageUrl &&
                    <CardMedia
                        component="img"
                        alt={product.name}
                        image={product.imageUrl}
                        width={300}
                        height={isSmall ? 250 : 200}
                        sx={{
                            borderRadius: theme.spacing(2)
                        }}
                    />
                }

                <CardContent>
                    <Typography
                        variant={'h5'}
                        fontWeight={'700'}
                        gutterBottom={!product.subtitle} textAlign={'center'}>
                        {product.name}
                    </Typography>
                    <Typography
                        variant={'h5'}
                        color={'neutral.dark'}
                        gutterBottom textAlign={'center'}>
                        {product.subtitle}
                    </Typography>
                    <MonoTypography
                        variant={'body1'}
                        textAlign={'center'}>
                        {product.description}
                    </MonoTypography>
                </CardContent>

                <Box sx={{ flexGrow: 1 }} />

                <CardActions>
                    <Button variant='contained' fullWidth onClick={(ev) => onAddToBasket(ev, product)}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: isLarge ? 'column' : 'row',
                        }}>

                        <Box>£{product.salePrice?.toFixed(2)}</Box>

                        {!isLarge && <Box>:&nbsp;</Box>}

                        <Box>Add to Basket</Box>

                    </Button>
                </CardActions>

            </FuocosCard>
        </Slide>
    );
};
