import React, { useState } from 'react';
import {
    Typography, Skeleton, Button, ButtonBase, Grid, ListItem, Box, useTheme, Tab, Tabs, Divider, Stack
} from '@mui/material';
import { ArrowBack, Add } from '@mui/icons-material';
import { useAuth, useFirestore, useFirestoreDocData } from 'reactfire';
import { doc, DocumentReference } from 'firebase/firestore';
import { COLLECTION_NAMES, OrderStatus } from '@hcstechnologies/fuocos-interfaces/dist/types';
import { TOrder } from '@hcstechnologies/fuocos-interfaces/src/types';
import { MonoTypography, FuocosCard, BlurredDialog } from '@hcstechnologies/fuocos-themes';
import baking from '../assets/status/baking.png';
import cutting from '../assets/status/cutting.png';
import pending from '../assets/status/pending.png';
import preparing from '../assets/status/preparing.png';
import readyforcollection from '../assets/status/readyforcollection.png';
import { formatTimestamp } from '../shared/utils';

type TOrderHistoryDialogArgs = {
    handleClose: (event?: unknown, reason?: 'escapeKeyDown' | 'backdropClick' | undefined) => void;
    open: boolean;
    orderId: string | undefined;
};

const OrderHistoryDialog: React.FC<TOrderHistoryDialogArgs> = ({ handleClose, open, orderId }) => {
    const theme = useTheme();
    const firestore = useFirestore();
    const [currentTab, setCurrentTab] = useState(0);
    const [width, setWidth] = useState<number>();

    const getStatusImage = () => {
        switch (orderDetails.status.code) {
            case OrderStatus.BAKE.code: return baking;
            case OrderStatus.PREP.code: return preparing;
            case OrderStatus.BOX.code: return cutting;
            case OrderStatus.NEW.code: return pending;
            case OrderStatus.AWAITING_PAYMENT.code: return pending;
            case OrderStatus.PENDING.code: return pending;
            case OrderStatus.READY_FOR_COLLECTION.code: return readyforcollection;

            // TODO: @t-r-griffin - Add correct images and map for out for delivery & delivered
            case OrderStatus.OUT_FOR_DELIVERY.code: return readyforcollection;
            case OrderStatus.DELIVERED.code: return readyforcollection;

            // TODO: @t-r-griffin - Add correct default
            default: return readyforcollection;

        }
    };

    const DisplayImage = (): JSX.Element => {
        return <img src={getStatusImage()} width='100%' onLoad={handleImageLoad} />;
    };

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        setWidth((event.target as HTMLImageElement).clientWidth);
    };

    type TabPanelProps = {
        children: React.ReactNode;
        index: number;
        value: number;
    };

    function TabPanel({ children, value, index, ...other }: TabPanelProps) {

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const auth = useAuth();
    if (auth.currentUser === null) throw new Error('Not Authenticated');

    const userRef = doc(firestore, COLLECTION_NAMES.USERS, auth.currentUser.uid);
    console.debug(userRef.path);

    const orderRef = doc(firestore, `${COLLECTION_NAMES.ORDERS}/${orderId}`) as DocumentReference<TOrder>;
    const orderDoc = useFirestoreDocData(orderRef);
    const orderDetails = orderDoc.data;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    const EstimatedTime = (): JSX.Element => {



        if (orderDetails.estimatedTimings?.completionWindow) {

            const fromTime = formatTimestamp(orderDetails.estimatedTimings.completionWindow[0]);
            const toTime = formatTimestamp(orderDetails.estimatedTimings.completionWindow[1]);

            return (
                <MonoTypography textAlign='center' fontWeight={700} pb={1}>
                    {fromTime} - {toTime}
                </MonoTypography>
            );

        }

        return (
            <MonoTypography textAlign='center' fontWeight={700} pb={1}>
                Calculating...
            </MonoTypography>
        );

    };


    return (
        <React.Fragment>
            {orderDoc.status === 'success' &&
                <BlurredDialog scroll='body' open={open} onClose={() => handleClose()} maxWidth={'sm'} >
                    <Grid sx={{ height: '100%', backgroundColor: theme.palette.secondary.main }}>
                        <ButtonBase onClick={() => handleClose()} sx={{ position: 'absolute', left: 2, top: 2 }}>
                            <ArrowBack fontSize="large" />
                        </ButtonBase>
                        <MonoTypography fontWeight={700} pt={2} pb={2} width='100%' fontSize={18} textAlign='center'>Order #{orderDetails.orderNumber}</MonoTypography>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs centered TabIndicatorProps={{
                                style: {
                                    marginLeft: 'auto',
                                },
                            }} value={currentTab} onChange={handleChange} aria-label="basic tabs example">
                                <Tab sx={{
                                    width: '50%', fontFamily: 'OverpassMono-Regular',
                                    '&.Mui-selected': { color: theme.palette.text.primary }
                                }} label="STATUS" {...a11yProps(0)} />
                                <Tab sx={{
                                    width: '50%', fontFamily: 'OverpassMono-Regular',
                                    '&.Mui-selected': { color: theme.palette.text.primary }
                                }} label="DETAILS" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Grid>
                    <Box sx={{ height: '70vh' }}>
                        <TabPanel value={currentTab} index={0}>
                            <MonoTypography textAlign='center'>Expected Completion Time</MonoTypography>
                            <EstimatedTime />
                            <Divider variant="middle" />
                            <MonoTypography textAlign='center' pt={1}>Current Status</MonoTypography>
                            <MonoTypography textAlign='center' color='green' mb={1} >{orderDetails.status.name}</MonoTypography>
                            {!width && <Skeleton variant="rectangular" width={340} height={320} />}
                            <DisplayImage />
                            <Box mt={1}>
                                <Button variant="contained"
                                    sx={{
                                        backgroundColor: 'green',
                                        height: 'max-content',
                                        color: 'white', width: '100%', '&:hover': { backgroundColor: '#006400', borderRadius: 2 }
                                    }}>
                                    <MonoTypography marginTop={theme.spacing(1)} marginBottom={theme.spacing(1)}>
                                        DIRECTIONS TO RESTAURANT
                                    </MonoTypography>
                                </Button>
                            </Box>
                        </TabPanel>
                        <TabPanel value={currentTab} index={1}>
                            {orderDetails.validatedBasket.allProducts.map((item, index) => (
                                <FuocosCard key={index} sx={{ width: width, borderRadius: 1, mb: 2 }}>
                                    <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Stack direction='row' width='100%'>
                                            <MonoTypography fontWeight={'700'} alignSelf='flex-start'>
                                                {item.productName}
                                            </MonoTypography >

                                            <Box marginLeft='auto' display='flex' flexDirection='column'>
                                                <MonoTypography fontWeight={700} >
                                                    £{item.baseAmount.toFixed(2)}
                                                </MonoTypography>
                                                <MonoTypography fontWeight={700} marginLeft='auto' >
                                                    x{item.quantity}
                                                </MonoTypography>
                                            </Box>
                                        </Stack>
                                        {item.extras.map((extra, index) => (
                                            <Stack direction='row' marginRight='auto' key={index}
                                                color={theme.palette.text.secondary}>

                                                <Add fontSize='small' />
                                                <MonoTypography fontWeight='bold' fontSize={theme.spacing(1.5)}>
                                                    {extra.productName} £{(extra.unitPrice * extra.quantity).toFixed(2)}
                                                </MonoTypography>

                                            </Stack>
                                        ))}

                                    </ListItem>

                                </FuocosCard>
                            )
                            )}

                            <Box sx={{ mt: '50%' }}>
                                <Divider sx={{ mb: 1 }} />
                                <Box sx={{ display: 'flex', }}>
                                    <MonoTypography fontWeight={'700'} >
                                        Order Total:
                                    </MonoTypography >
                                    <Box style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
                                        <MonoTypography fontWeight={700} marginLeft='auto'>
                                            £{orderDetails.validatedBasket.totalAmount.toFixed(2)}
                                        </MonoTypography>
                                        <MonoTypography fontSize='.8rem' marginLeft='auto'>
                                            Includes VAT (£{orderDetails.validatedBasket.totalTax.toFixed(2)})
                                        </MonoTypography>
                                        {orderDetails.validatedBasket.deliveryCharge !== undefined && (
                                            <MonoTypography fontSize='.8rem' marginLeft='auto'>
                                                Includes Delivery Charge (£{orderDetails.validatedBasket.deliveryCharge.totalAmount.toFixed(2)})
                                            </MonoTypography>
                                        )}
                                    </Box>
                                </Box>
                                <Divider sx={{ mt: 1 }} />
                                <Box sx={{ mt: 4 }}>
                                    <Button
                                        fullWidth
                                        variant="contained" sx={{
                                            backgroundColor: theme.palette.success.main,
                                            color: theme.palette.success.contrastText,
                                            '&:hover': {
                                                backgroundColor: theme.palette.success.main,
                                            },
                                            display: 'none'
                                        }}>
                                        <MonoTypography marginTop={theme.spacing(2)} marginBottom={theme.spacing(2)}>
                                            RATE MY PIZZA
                                        </MonoTypography>
                                    </Button>
                                </Box>

                            </Box>
                        </TabPanel>

                    </Box>
                </BlurredDialog>
            }
        </React.Fragment>

    );

};

export default OrderHistoryDialog;
