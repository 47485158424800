import React from 'react';
import Menu from '../components/menu';

type TMenuPageArgs = {
    navBarHeight: number
};

const MenuPage: React.FC<TMenuPageArgs> = ({ navBarHeight }) => {

    return (
        <Menu navBarHeight={navBarHeight} />
    );

};

export default MenuPage;
