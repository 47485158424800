import React from 'react';
import { useSigninCheck, useAuth } from 'reactfire';
import { signInAnonymously } from 'firebase/auth';
import Loading from '../components/loading';

/**
 * Ensures user is logged in before displaying content
 * Currently only supports or requires anonymous login
 */
const AuthedNavigation: React.FC<React.PropsWithChildren<any>> = ({ children }) => {

    const auth = useAuth();
    const { status, data: signInCheckResult } = useSigninCheck();

    if (status === 'loading') return <Loading fillViewport={true} message={'Authenticating...'} />;

    console.debug(`signInCheckResult.signedIn:${signInCheckResult.signedIn}, UID: ${signInCheckResult.user?.uid}`);

    if (signInCheckResult.signedIn === false) {
        console.debug('signInAnonymously');
        signInAnonymously(auth);
    }

    return children;

};

export default AuthedNavigation;
