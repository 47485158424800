import React, { useEffect, useState } from 'react';
import {
    Stack, DialogTitle, DialogContent, List, Button,
    TextField, Divider, CardMedia, Typography, CardContent, CardActionArea, Grid, LinearProgress
} from '@mui/material';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import SearchIcon from '@mui/icons-material/Search';
import PinDrop from '@mui/icons-material/PinDrop';
import { useSnackbar } from 'notistack';
import useLocation from '../hooks/use-location';
import { Box, useTheme } from '@mui/system';
import { FuocosCard, MonoTypography, BlurredDialog } from '@hcstechnologies/fuocos-themes';
import { normaliseError } from '../shared/utils';
import { TInRangeResponse } from '@hcstechnologies/fuocos-interfaces/dist';

type TSelectRestaurantDialogArgs = {
    open: boolean;
    handleClose: (event?: unknown, reason?: 'escapeKeyDown' | 'backdropClick' | undefined) => void;
    handleRestaurantSelect: (restaurant: TInRangeResponse, postcode: string) => void;
};

/**
 * Display a dialog with that allows users to enter their postcode and select their preferred restaurant, if one exists
 * @param param0
 * @returns
 */
const SelectRestaurantDialog: React.FC<TSelectRestaurantDialogArgs> = ({ open, handleClose, handleRestaurantSelect }) => {

    const { getRestaurantsForLocation, getPostcodeForCurrentLocation } = useLocation();
    const [currentPostcode, setCurrentPostcode] = useState<string>('');
    const [restaurants, setRestaurants] = useState<TInRangeResponse[]>();
    const [busy, setBusy] = useState<boolean>(false);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        handleGetPostcode();
    }, []);

    /* On postcode icon button click, retrieve the users postcode if we're able to, and store it locally */
    const handleGetPostcode = async (): Promise<void> => {
        setBusy(true);

        try {
            const postcode = await getPostcodeForCurrentLocation();
            setCurrentPostcode(postcode.result[0].postcode);
            await handleGetRestaurants(null);
        } catch (error) {
            console.error(error);
            const e = normaliseError(error);
            enqueueSnackbar(e.message, { variant: 'error' });
        }

        setBusy(false);
    };

    /* On search, retrieve all restaurants for a given postcode & store them locally */
    const handleGetRestaurants = async (ev: React.FormEvent | null): Promise<void> => {
        ev?.preventDefault();

        setBusy(true);

        try {
            const restaurants = await getRestaurantsForLocation(currentPostcode);
            setRestaurants(restaurants);
        } catch (error) {
            setRestaurants([]);
        }

        setBusy(false);
    };

    const radius: string = theme.spacing(2);

    return (
        <BlurredDialog open={open} onClose={handleClose} scroll="paper" maxWidth={'sm'}>
            <DialogTitle>

                <Typography mb={2} textAlign={'center'}>
                    Search for Restaurant
                </Typography>

                <form noValidate onSubmit={(ev) => handleGetRestaurants(ev)}>
                    <Stack direction={'row'} alignContent='center'>

                        <Button type={'button'} variant='contained' onClick={handleGetPostcode} disabled={busy} sx={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderTopLeftRadius: radius,
                            borderBottomLeftRadius: radius,
                        }}>
                            <LocationSearchingIcon />
                        </Button>

                        <TextField
                            autoComplete={'postal-code'}
                            placeholder='Postcode'
                            value={currentPostcode}
                            onChange={(ev) => setCurrentPostcode(ev.currentTarget.value)} disabled={busy} />

                        <Button type='submit' variant='contained' disabled={busy} sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopRightRadius: radius,
                            borderBottomRightRadius: radius,
                        }}>
                            <SearchIcon />
                        </Button>

                    </Stack>
                </form>

            </DialogTitle>

            {busy && <LinearProgress variant='indeterminate' />}

            <Divider variant="fullWidth" />

            <DialogContent>

                {restaurants?.length == 0 &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <PinDrop fontSize="large" sx={{ mb: theme.spacing(2) }} />
                        <Typography gutterBottom variant="body1">
                            Sorry - no restaurants in your area yet
                        </Typography>
                    </Box>}

                <List>
                    {restaurants?.map((restaurant, index) => (
                        <FuocosCard
                            key={index}
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                            elevation={0}
                            onClick={() => handleRestaurantSelect(restaurant, currentPostcode)}>

                            <CardActionArea>

                                <CardMedia
                                    component="img"
                                    image={restaurant.location.imageUrl}
                                    alt={restaurant.location.name}
                                    loading='lazy'
                                    sx={{
                                        height: theme.spacing(24),
                                    }} />

                                <CardContent>
                                    <Grid container key={index} direction="row" sx={{ mb: theme.spacing(2) }}>
                                        <Grid item xs>
                                            <Typography variant="h5" fontWeight={'700'}>
                                                {restaurant.location.name}
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <MonoTypography variant="body2" sx={{ mt: theme.spacing(1.5) }}>
                                                {restaurant.estimatedDuration.text}
                                            </MonoTypography>
                                        </Grid>
                                    </Grid>

                                    <MonoTypography variant="body2" color="text.secondary">
                                        {restaurant.location.address1}, {restaurant.location.address2}
                                    </MonoTypography>

                                    <MonoTypography variant="body2" color="text.secondary">
                                        {restaurant.location.postcode}, {restaurant.location.town}
                                    </MonoTypography>

                                    <Typography variant="body2" color="text.primary" align='center' sx={{ mt: theme.spacing(1), mb: theme.spacing(1) }}>
                                        Opening Hours
                                    </Typography>

                                    {restaurant.location.openingHours?.map((openingHour, index) =>
                                        <Grid container key={index} direction="row">
                                            <Grid item xs>
                                                <MonoTypography variant="caption" color="text.secondary" sx={{ mt: theme.spacing(1) }}>
                                                    {openingHour.day}
                                                </MonoTypography>
                                            </Grid>

                                            <Grid item>
                                                <MonoTypography variant="caption" color="text.secondary" sx={{ mt: theme.spacing(1) }}>
                                                    {openingHour.from} to {openingHour.to}
                                                </MonoTypography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </CardContent>
                            </CardActionArea>

                        </FuocosCard>
                    ))}
                </List>

            </DialogContent>

        </BlurredDialog>

    );

};

export default SelectRestaurantDialog;
