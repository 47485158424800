import { Timestamp } from '@hcstechnologies/fuocos-interfaces/src/types-proxy';
import { DateTime } from 'luxon';

/** Assertion check for null values */
export function assertNotNull(value: unknown): asserts value {
    if (value === null) throw new Error('NULL_VALUE_ERROR');
}

/** Assertion check for null values */
export function assert(value: unknown, message = 'ASSERT_ERROR'): asserts value {
    if (value !== true) throw new Error(message);
}


/**
 * Makes errors typesafe by normalising to the Error object
 * @param err
 * @returns
 */
export function normaliseError(err: unknown): Error {

    if (err instanceof Error) return err;

    if (typeof err === 'string') return new Error(err);

    if (typeof err === 'number') return new Error(err.toString());

    if (typeof err === 'object') return new Error(JSON.stringify(err));

    // Catch all, not ideal
    // TODO: Handle onCall errors from firebase
    return new Error(JSON.stringify(err));

}

/**
 * Take a represenation of a timestamp and convert to a string
 * @param timestamp
 * @param format
 * @returns
 */
export function formatTimestamp(timestamp: Date | Timestamp | string | null): string {

    if (timestamp instanceof Date) return DateTime.fromJSDate(timestamp).toFormat('h:mm a').toLocaleString();

    if (typeof timestamp === 'string') return DateTime.fromISO(timestamp).toFormat('h:mm a').toLocaleString();

    if (timestamp !== null && 'toDate' in timestamp) return DateTime.fromJSDate(timestamp.toDate()).toFormat('h:mm a').toLocaleString();

    throw new Error(`${timestamp} is not a valid timestamp`);

}
