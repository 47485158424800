import { TInRangeResponse } from '@hcstechnologies/fuocos-interfaces/src/types';
import React, { useState, useContext, Dispatch, SetStateAction } from 'react';
import { noop } from 'lodash';

type TLocationContext = {
    location: TInRangeResponse | null;
    setLocation: Dispatch<SetStateAction<TInRangeResponse | null>>;
}

/**
 * Set default Location context in case Postcode Provider isn't used
 */
export const LocationContext = React.createContext<TLocationContext>({
    location: null,
    setLocation: noop,
});

type TPostcodeContext = {
    postcode: string,
    setPostcode: Dispatch<SetStateAction<string>>;
}

/**
 * Set default Postcode context in case Postcode Provider isn't used
 */
export const PostcodeContext = React.createContext<TPostcodeContext>({
    postcode: '',
    setPostcode: noop,
});

/**
 * Standard Location provider
 */
export const LocationProvider: React.FC = ({ children }) => {
    const [location, setLocation] = useState<TInRangeResponse | null>(null);
    return (
        <LocationContext.Provider value={{ location, setLocation }}>
            {children}
        </LocationContext.Provider>
    );
};

/**
 * Standard Postcode provider
 */
export const PostcodeProvider: React.FC = ({ children }) => {
    const [postcode, setPostcode] = useState<string>('');
    return (
        <PostcodeContext.Provider value={{ postcode, setPostcode }}>
            {children}
        </PostcodeContext.Provider>
    );
};


/**
 * Hook to make sure that setPostcode is not undefined, otherwise throw an error
 */
export const usePostcodeContext = (): TPostcodeContext => {
    const postcodeValue = useContext(PostcodeContext);

    if (postcodeValue.setPostcode === undefined) throw new Error('setPostcode is not defined');

    return postcodeValue;
};

/**
 * Hook to make sure that setLocation is not undefined, otherwise throw an error
 */
export const useLocationContext = (): TLocationContext => {
    const locationValue = useContext(LocationContext);

    if (locationValue.setLocation === undefined) throw new Error('setLocation is not defined');

    return locationValue;
};
