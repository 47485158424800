
/**
 * NOTES:
 *
 * 1. Naming conventions:
 *    *Id: is the ID for a record, used typically to reference an Id to an external system
 *    *Ref: is a DocumentReference to a specific document in firestore, cannot be used in API calls
 *    *Path: is the full path to a Document in Firestore. Can be used to construct a DocumentReference
 *
 */


/** We import references through a proxy to enable a shared interfaces file between backend and frontend */
import { GeocodeResponseData } from '@googlemaps/google-maps-services-js';
import { WebhookPayload } from './stripe.interfaces';
import {
    Invoice, Account, Timestamp, GeoPoint, CollectionReference,
    DocumentReference, FieldValue, DocumentData, Refund, Reason,
    QueryDocumentSnapshot, DocumentSnapshot, BalanceTransaction, PaymentIntent, PurchaseOrder
} from './types-proxy';


/**
 * Used by some api calls to ensure that the api
 * compatibility  remains constant across versions
 */
export const MINIMUM_VERSION = '3.0.0';

/**
 * Key to allow access to google maps API
 * TODO: Move to remote config / firebase config
 * */
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDeW_eS2wbz2TyPrBF00uMEvZ4AgrYutw0';


/** Utility type to incorporate both query and document snapshot types */
export type TFirestoreSnapshot<T = DocumentData> = QueryDocumentSnapshot<T> | DocumentSnapshot<T>;

/**
 * Root collection names for firestore
 */
export const COLLECTION_NAMES = {
    ACCOUNTS: 'accounts',
    BASKETS: 'baskets',
    CLEARED_BASKETS: 'clearedBaskets',
    CONTACTS: 'contacts',
    DEVICES: 'devices',
    DISCOUNT_RATES: 'discountRates',
    INGREDIENTS: 'ingredients',
    KIOSKS: 'kiosks',
    LOCATION_TRACKING: 'locationTracking',
    LOCATIONS: 'locations',
    MENU: 'menu',
    ORDER_REF: 'orderRef',
    ORDERS: 'orders',
    OUT_OF_STOCK: 'outOfStock',
    PHOENIX_MENU: 'phoenixMenu',
    PHOENIX_MENU_SCHEDULE: 'phoenixMenuSchedule',
    PRIVATE_PRODUCT_DETAILS: 'privateProductDetails',
    PRIVATE_ORDER_DETAILS: 'privateOrderDetails',
    PRODUCT_TAGS: 'productTags',
    PRODUCTS: 'products',
    SCHEDULER_TIMING_STATS: 'schedulerTimingStats',
    SETTINGS: 'settings',
    STAFF_ROTAS: 'staffRotas',
    STOCK_LEVELS: 'stockLevels',
    STOCK_LOCATIONS: 'stockLocations',
    STOCK_MOVEMENTS: 'stockMovements',
    STOCK_TAKE: 'stockTake',
    STOCK_TAKE_LEVELS: 'stockTakeLevels',
    STRIPE_EVENTS: 'stripeEvents',
    TAX_RATES: 'taxRates',
    TIMESHEETS: 'timesheets',
    TIP_TYPES: 'tipTypes',
    USERS: 'users',
    XERO_INVOICE: 'xeroInvoice',
    PURCHASE_ORDERS: 'purchaseOrders',
    PURCHASE_ORDER_PRODUCTS: 'purchaseOrderProducts',
    PURCHASE_ORDER_RECEIPTS: 'purchaseOrderReceipts',
    PRODUCT_CATEGORIES: 'productCategories',
    GET_IN_RANGE_LOCATIONS: 'getInRangeLocations',
    ABANDONED_BASKETS: 'abandonedBaskets',
    CHECKLIST_TASK_TEMPLATE: 'checklistTaskTemplate',
    CHECKLIST_CATEGORY: 'checklistCategory',
    CHECKLIST_TASKS: 'checklistTasks',
    CHECKLIST_SUMMARY: 'checklistSummary',
    PRODUCT_MAKE_INSTRUCTIONS: 'productMakeInstructions',
    STRIPE_WEBHOOKS: 'stripeWebhooks',
    PRINTER_LOGGING: 'printerLogging',
    APP_HOME: 'appHome',
    PREFERENCES: 'preferences',
} as const;

/** Topic names that can be subscribed to */
export const TOPIC_NAMES = {
    KIOSK_ALERTS: 'storageUpdate'
} as const;

/** Simple RegEx to validatr email address */
export const EMAIL_REGEX = /[^@]+@[^\.]+\..+/;

/** FirestorePath is a path representation of a DocumentReference.path, this is used in API requests/responses as DocumentReference does not serialize well */
export type FirestorePath = string;


/** Utility type to add id: string to any type */
export type RecWithId<T> = { id: string } & T;


/** Tenant type */
export type TTenant = 'emulator' | 'dev' | 'prod';


/**
 * Defines the public view of what the menu looks like
 * Each category can have both sub categories and products
 */
export type TMenuCategory = {

    /** If the menu is active, this is set automatically based upon activeOn/activeTimeslot */
    active: boolean;

    /** If true, users logged in with an anonymous account are allowed to view the menu */
    anonymousAllowed?: boolean;

    /** Display the subcategory on the website */
    includeOnWebsite?: boolean;

    /** Display the subcategory on the overhead menus */
    overheadMenu?: 'full' | 'compact' | 'hide';

    /** Colour for the header on overhead menus */
    headerColour?: string;

    /** Name of Category */
    name: string;

    /** Extra (limited) data for displaying to user */
    subtitle?: string;

    /** Full description to display to user */
    description?: string;

    /**
     * Image to be displayed in category card, image will have a ratio of 3x2 (WxH)
     * Lazy load using an image loading placeholder, if the value is undefined use a default image (to be defined)
     */
    imageUrl?: string | null;

    /** List of all products associated with the category */
    products?: Array<TMenuProduct>;

    /** Categories can be nested, this displays those nested categories */
    childCategories?: Array<TMenuSubCategory>;

    /**
     * Sort order is used to influence display order of the category,
     */
    sortOrder: number;

    /** Days the menu is active (visible on screen) on, this can be undefined on legacy menus */
    activeOn?: Array<TDay>;

    /** Times the menu is available, used to restrict availability times on checkout (e.g. for lunch only items) */
    availableTimeslot?: TTimeslot;

    /** Timeslot when the active property should be true, making the menu visible */
    activeTimeslot?: TTimeslot;

    /** If true, do not display the title, subtitle & description on the display card */
    hideText?: boolean;

};

/**
 * Defines the public view of what the home looks like
 * Each item can have both sub categories and products
 */
export type TAppHomeItem = {

    /** If the menu is active, this is set automatically based upon activeOn/activeTimeslot */
    active: boolean;

    /** Name of Item */
    name: string;

    /** Cell Width of the Item */
    cellWidth: number;

    /** Cell Height of the Item */
    cellHeight: number;

    /** Extra (limited) data for displaying to user */
    subtitle?: string;

    /** Full description to display to user */
    description?: string;

    /**
     * Image to be displayed in item card, image will have a ratio of 3x2 (WxH)
     * Lazy load using an image loading placeholder, if the value is undefined use a default image (to be defined)
     */
    imageUrl?: string | null;

    /**
     * Sort order is used to influence display order of the item,
     */
    sortOrder: number;

    /** If true, do not display the title, subtitle & description on the display card */
    hideText?: boolean;

    /** The reference to the firestore collection or document we want this home item to lead to */
    doc?: string;

    /** The link to the subcollection or product of the document */
    link?: string;

    /** If true, ignore the doc + link & link straight to the menu page on the app */
    linkToMenuPage?: boolean;

    /** If true, ignore the doc + link & link straight to the deal page on the app */
    linkToDealsPage?: boolean;

    /** If true, ignore the doc + link & link straight to the url provided */
    linkToUrl?: boolean;

    /** If true, ignore any links - this item is purely visual */
    noLink?: boolean;

};

/** Child of root TMenuCategory does not have active or sortOrder props */
export type TMenuSubCategory = Omit<TMenuCategory, 'active' | 'activeOn' | 'availableTimeslot' | 'activeTimeslot'> & {

    /**
     * BuyXGetY Discount rate
     * Only used on sub categories
     */
    discount?: number;

    /**
     * Sort order is used to influence display order of the chuld categories,
     */
    sortOrder?: number;


};

export type TDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

/**
 * A period of time consisting of a from time and to time in HH:MM format
 */
export type TTimeslot = {

    /** Availability start time in HH:MM format */
    fromTime: string;

    /** Availability end time in HH:MM format */
    toTime: string;

};

/**
 * Deals are a type of menu where the customer can choose multiple items at a fixed or discounted price
 *
 * Deal Type 1: Multi-Deal
 * Deal Type 2: Buy X get Y for a % discount
 */
type TBaseDealCategory = TMenuCategory & {

    /** Products cannot be added to a deal, instead they need to be added to childCategories */
    products: never;

    /** Deals cannot be dispplayed on the web */
    includeOnWebsite: never;

    /** If a deal is collection only do not display delivery slots on checkout */
    collectionOnly?: boolean;

};

/** Properties specific to Multi-Deals */
export type TMultiDeal = TBaseDealCategory & {

    /** Deal type */
    dealType: 'multi';

    /** Deal Price, this is the combined price of all products added to the deal */
    dealPrice: number;

};

/** Properties specific to BuyXGetY deals */
export type TBuyXGetYDeal = TBaseDealCategory & {

    /** Deal type */
    dealType: 'buyXgetY'

};

/** Deal specific menu categories */
export type TDealCategory = TMultiDeal | TBuyXGetYDeal;

/** Combined type for root categories */
export type TRootCategory = TMenuCategory | TDealCategory;

/** Combined type for menu categories */
export type TAnyCategory = TMenuCategory | TMenuSubCategory | TDealCategory;

/** Representation of product that appears on the menu */
export type TMenuProduct = TProduct & {

    /** ID */
    productId: string;

};


export type TProductAccount = Pick<TAccount, 'accountId' | 'code' | 'name'>;
export type TProductSupplier = Pick<TContact, 'contactId' | 'name'>;

/** Origin of a product, made or bought */
export type TProductOrigin = 'buy' | 'make';

/** Type of measurements products may use */
export type TMeasureType = 'weight' | 'volume' | 'qty';

/** Recognised units of measure */
export type TMeasure = 'ml' | 'l' | 'mg' | 'g' | 'kg';

/**
 * The actual underlying product that can be ordered.
 * TODO: Product properties need to be enhanced for other (tbc) stuff e,g, costs, suppliers etc
 * TODO: We need an internal product Type and an external product type, the external version is what we send to client applications and excludes sensitive information
 */
export type TProduct = {

    /**
     * Name to appear on product list
     * Display as text input
     * min length 3
     * max length 50
     */
    name: string;

    /**
     * If true, do not allow delivery slots
     */
    collectionOnly?: boolean;

    /**
     * Name of product as used by the supplier
     */
    supplierProductName?: string;

    /**
     * Extra limited detail to appear on product list
     * Display as text input
     * min length 3
     * max length 50
     */
    subtitle?: string;

    /**
     * How the product is acquired
     * Buy: Add supplier info and purchase prices
     * Make: Add ingredients views
     */
    origin: TProductOrigin;
    /**
     * If product is CPU product
     * true: CPU producr
     * false: external suppliers
     */
    cpuOnly: boolean;
    /**
     * Full description to appear on product card
     * Display as textarea (multi line)
     * min length undefined
     * max length 200
     */
    description?: string;

    /**
     * Price to display to user, in pounds & pence and includes tax
     * Display as numeric input
     * min val 0
     * no max
     */
    salePrice?: number;

    /**
     * Standard qty per sale
     * Display as numeric input
     * min val 0
     * no max
     */
    saleQty?: number;

    /**
     * Type of measurement used by a product
     * Display as select
     */
    measurementType?: TMeasureType

    /**
     * Standard unit per qty of sale, filtered based upon TMeasureType
     * Display as select
     */
    saleUnit?: TMeasure;

    /**
    * Minimum amount of stock required before re-order
    */
    stockAlertUnits?: number;


    /** Size of doughballs  */
    doughBallSize?: '13"' | '11"' | '9"';

    /**
     * Tax rate to charge client
     *
     * linked to COLLECTIONS.TAX_RATES
     * Display as Select component
     */
    salesTaxRate?: TTaxRate;

    /**
     * Tax rate to charge client when order.eatIn === true
     *
     * If null, back end should assume salesTaxRate for eatIn orders
     *
     * linked to COLLECTIONS.TAX_RATES
     * Display as Select component
     */
    eatInSalesTaxRate?: TTaxRate;

    /**
     * Price to display to user when order.eatIn === true, in pounds & pence and includes tax
     * Display as numeric input
     * min val 0
     * no max
     */
    eatInSalesPrice?: number;

    /**
     * Account to allocate to sales
     * linked to COLLECTION_NAMES.ACCOUNTS
     * Display as Select component
     */
    salesAccount?: TProductAccount;

    /**
     * Account to allocate to sales when order.eatIn === true
     * linked to COLLECTION_NAMES.ACCOUNTS
     * Display as Select component
    */
    eatInSalesAccount?: TProductAccount;

    /**
     * Price to display to user, in pounds & pence and excludes tax
     * Display as numeric input
     * min val 0
     * no max
     */
    purchasePrice?: number;

    /**
     * Standard qty per purchase
     * Display as numeric input
     * min val 0
     * no max
     */
    purchaseQty?: number;

    /**
     * Standard unit per qty of purchase
     * Display as select
     */
    purchaseUnit?: TMeasure;

    /**
     * Multiplier to get units from cases
     * Multiple of units
     * min val 1
     * no max
     * If not set, defaults to 1
     */
    unitsPerCase: number;

    /**
     * Multiplier to get portions from units
     * This is used to handle movement/discard of stock from store locations only
     * Multiple of units
     * min val 1
     * no max
     * If not set, should be defaulted to 1.
     */
    portionsPerUnit?: number;

    /**
     * Tax rate to charge client
     *
     * linked to COLLECTIONS.TAX_RATES
     * Display as Select component
    */
    purchaseTaxRate?: TTaxRate;

    /**
     * Account to allocate to purchases
     * linked to COLLECTION_NAMES.ACCOUNTS
     * Display as Select component
     */
    purchaseAccount?: TProductAccount;

    /**
     * Yield quantity
     * Applicable for CPU and type make
     * Numeric input
     * Min 0, no max
     */
    yieldQty?: number;

    /**
     * Yield unit
     * Applicable for CPU and type make
     * Select input
     */
    yieldUnit?: TMeasure;

    /**
     * Supplier to use for purchases
     * linked to COLLECTION_NAMES.CONTACTS
     * Display as Select component
     */
    supplier?: TProductSupplier;

    /**
     * Code used by supplier, use on PO
     * Display as text input component
     */
    supplierProductCode?: string;

    /**
     * Image to be displayed in card, image will have a ratio of 3x2 (WxH)
     * Lazy load using an image loading placeholder
     * Mobile app: If the value is undefined then display the alternative list view
     *
     * Restaurant PWA
     * Display image, if does not exist display placeholder
     * Hover over image to display translucent upload image button
     * Upload image to STORAGE.PRODUCTS[productId]
     * Save publicUrl to document
     *
     */
    imageUrl?: string;

    /**
     * Second Image to be displayed in card, image will have a ratio of 3x2 (WxH)
     * Lazy load using an image loading placeholder
     * Mobile app: If the value is undefined then display the alternative list view
     *
     * Restaurant PWA
     * Display image, if does not exist display placeholder
     * Hover over image to display translucent upload image button
     * Upload image to STORAGE.PRODUCTS[productId]
     * Save publicUrl to document
     *
     */
    secondaryImageUrl?: string;

    /**
     * List of tags to display on product screen, typically used to display allergen data or suitable for vegetarians etc
     *
     * Display as chips, with select to add from the COLLECTIONS.
     *
     */
    tags?: Array<RecWithId<TTags>>;

    /**
     * The amount of space the product will take up in oven as a proportion of oven capacity
     * e.g. 1 means that the product will take up 1 space in oven 2 means it will take up twice that space
     * Display as numeric input
     * min value 0 | undefined
     * max value none
     */
    ovenMultiple?: number;

    /**
     * Time in seconds it takes to prepare product
     * Display as numeric input
     * min value 0 | undefined
     * max value none
     */
    prepTime?: number;

    /**
     * Time in seconds it take to bake product
     * Display as numeric input
     * min value 0 | undefined
     * max value none
     */
    bakeTime?: number;

    /**
     * Time in seconds it take to box product
     * Display as numeric input
     * min value 0 | undefined
     * max value none
     */
    boxingTime?: number;

    /**
     * Display on topping screen
     * Display as checkbox
     */
    toppingScreen?: boolean;

    /**
     * Display on pizziola screen
     * Display as checkbox
     */
    pizziolaScreen?: boolean;

    /**
     * Include on website export
     * Display as checkbox
     */
    includeOnWebsite?: boolean;

    /**
     * List of options that can be added to a pizza (e.g. extra cheese)
     *
     * UI: Display as a DataGrid on the products page. Add a button "Edit" that displays a dialog transfer list
     *
     * (https://material-ui.com/components/transfer-list/)
     *
     * Displays any product where a salePrice exists
     *
     */
    extraOptions?: Array<TExtraOption>;

    /**
     * Defines stock storage locations for a product
     * Display as a list
     */
    storageLocations: Array<TProductStorageLocation>;

    /** Category assigned to the product (id added as additional property) */
    category?: RecWithId<TProductCategory>;

    /** Free text for recording notes about the product */
    notes?: string;

    /** If true, requires a separate label to be printed for boxing station, used to stick to packaging for order */
    boxingLabel?: boolean;

    /** Record if proof of age is required on purchase */
    proofOfAgeRequired?: boolean;

    /** If true, display text "Vegan Option Available" on overhead menu */
    veganOptionAvailable?: boolean;

    /** If true, product will be included in the list of free pizza for first time user */
    firstPizzaFreeEligible?: boolean;

    /** Removable toppings, calculated array from ingredients sub collection */
    removableToppings?: TRemovableTopping[];

    /**
     * List of modifiers that can be applied to the product.
     * This is to allow for options such as milk / exra shot for drinks
     * Also allows for addition of extra items such as sides, when adding a product
     */
    modifiers?: TProductModifiers[];

    /**
     * If true, do not display imageUrl on the product card in a menu
     */
    doNotDisplayImageUrl?: boolean;

    /**
     * Flag to designate if the product includes any instructions documents
     * Set by trigger that watches the productMakeInstructions sub-collection
     */
    hasInstructions?: boolean;

};


/**
 * Other options are options that should appear separately from the main 'extraOptions' list,
 *
 */
export type TProductModifiers = {

    /** Label to use as title for section */
    label: string;

    /** Minimum number of options required, if > 0 then add a "required" chip to display */
    minRequired: number;

    /** Maximum number of options that can be chosen, if > 1 add text "Select up to {maxAllowed}" */
    maxAllowed: number;

    /**
     * List of items that can be added
     *
     * If one item available display as checbox,
     *
     * If multiple items are available and minRequired === 1 maxAllowed === 1 display as radio buttons
     * - Display a "REQUIRED" chip
     *
     * If multiple items are available and minRequired === 0 and maxAllowed >= 1 display a list of checkboxes
     * - Display a "SELECT UP TO {maxAllowed}" chip
     * - if > maxAllowed are selected, make chip bg deepred
     *
     * If multiple items are available and minRequired === 1 && maxAllowed > 1 display a list of checkboxes
     * - Display a "SELECT BETWEEN {minRequired} & {maxAllowed}" chip
     * - if > maxAllowed are selected, make chip bg deepred
     * - if < minRequired are selected, make chip bg deepred
     *
     */
    options: TExtraOption[];

    /**
     * If extra, add to basket in the TValidateBasketRequestItem.extras array
     * If item, add as a separate item in the TValidateBasketRequestItem array
     */
    addAs: 'item' | 'extra';

};



export type TRemovableTopping = Pick<TIngredient, 'name' | 'productId'>;

/** Category assgigned to a product */
export type TProductCategory = {
    name: string;
};


/** Defines stock storage locations for a product */
export type TProductStorageLocation = {

    /** id of document (mapped to COLLECTION.STOCK_LOCATIONS ) */
    storageLocationId: string;

    /** Name of storage location */
    storageLocationName: string;

    /**
     * Duration product can be stored at location (m,h,d)
     * e.g. 2h = 2 hours, 10m = 10 minutes
     * regex: /\d+(m|h|d)/
     */
    lifespan: string;

    /** Qty of unit that will alert the user to low levels */
    stockLevelWarningQty: number;

    /**
     * Unit of qty that will alert the user to low levels
     * Defined in same unit as the product TMeasureType
     */
    stockLevelWarningUnit: TMeasure;

};


/** Copy of products for website  */
export type TWebsiteProduct = Pick<TProduct, 'name' | 'subtitle' | 'description' | 'imageUrl' | 'secondaryImageUrl' | 'salePrice'>;


/**
 * SubCollection to record the ingredients used to make a product
 */
export type TIngredient = Pick<TProduct, 'name' | 'measurementType'> & {

    productId: string;

    measurementUnit: TMeasure;

    measurementAmount: number;

    /** If true, option can be removed on request by customer */
    removable?: boolean;

    /** Calculated cost for the ingredient, set via a trigger */
    netCost?: number;

    /** Calculated cost for the ingredient, set via a trigger */
    grossCost?: number;

    /**
     * Id of stock location to deduct stock from
     * If undefined, stock will be deducted from the last stock location
     * in the stock locations array of a product
     */
    stockLocationId?: string;

};


/** Extra Options that can be added to a menu item */
export type TExtraOption = {

    /** Name of extra option, to display on extras screen */
    name: string;

    /** Product ID for the menu, linked to the underlying product in the products collection */
    productId: string;

    /** Price to charge customer */
    salePrice: number;

};

/** User roles */
export type TRoles = 'restaurantAdmin' | 'technicalSupport' | 'payments' | 'driver' | 'kiosk' | 'franchisorAdmin' | 'api';

export type Role = {
    name: string;
    description?: string;
};

/** Description of User Roles */
export const UserRoles: Record<TRoles, Role> = {
    restaurantAdmin: {
        name: 'Restaurant Admin',
    },
    technicalSupport: {
        name: 'Technical Support',
    },
    payments: {
        name: 'Payments Webhooks',
    },
    driver: {
        name: 'Delivery Driver',
    },
    kiosk: {
        name: 'Kiosk App',
    },
    franchisorAdmin: {
        name: 'Franchisor Admin',
    },
    api: {
        name: 'API User',
    },
};

/** User record used to store user profile, permissions etc */
export type TUser = {

    created: Date | Timestamp;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    displayName?: string | null;
    photoUrl?: string | null;

    /** Marketing Preferences */
    marketingEmailOptIn?: boolean;
    marketingSmsOptIn?: boolean;
    marketingPushOptIn?: boolean;

    // UserRecord at time of creating the account
    json: string;

    // Copy of permissions set on the readonly document
    permissions?: TUserPermissions;

    // Copy of discount rate set on the readonly document
    discountRate?: TDiscountRate;

    /** Sub collection of read only documents used to store items a user needs access to but cannot write to */
    readonly?: {
        permissions: TUserPermissions;
        discountRate: TDiscountRate;
    };

    /** Sub Collection of documents used to store user preferences */
    preferences?: CollectionReference;

    /** Used to store the users last known location, used to track driver location */
    currentLocation?: TLocation;

    /** Records most recently used device */
    activeDevice?: string;

    /** Array of delivery addresses */
    deliveryAddresses?: Array<TDeliveryAddress>;

    /** List of allergens the user has requested to be alerted to */
    allergens?: Array<TTags>;

    /** For drivers, list future availability */
    availability?: Array<TDriverAvailability>;

    /** For drivers, list all allocated deliveries */
    allocatedDeliveries?: Array<TDriverDelivery>;

    /** If a user has alreadt used the first pizza free offer, record it on their user record  */
    firstPizzaFreeUsed?: boolean;

    /** Stripe customerId */
    stripeCustomerId?: string;

};

/** Order fulfilment - i.e. Delivery or Collection */
export type TOrderType = 'COLLECTION' | 'DELIVERY';

/** Orders can originate from different sources which will impact workflows */
export type TOrderOrigin = 'ONLINE' | 'KIOSK';

/** Order Status is represented by an object with code and name fields */
export type TOrderStatus = 'NEW' | 'AWAITING_PAYMENT' | 'PENDING' | 'PREP' | 'BAKE' | 'BOX' | 'OUT_FOR_DELIVERY' |
    'READY_FOR_COLLECTION' | 'READY_FOR_DELIVERY' | 'DELIVERED' | 'COLLECTED' | 'DRIVER_UNAVAILABLE' | 'INVALID_ADDRESS' |
    'CANCELLED' | 'REFUNDED' | 'OUT_OF_RANGE' | 'OUT_OF_HOURS' | 'FAILED_PAYMENT' | 'COMPLETE';

export type IOrderStatus = {
    code: number;
    name: string;
};

export const OrderStatus: Record<TOrderStatus, IOrderStatus> = {

    NEW: { code: 0, name: 'New' },
    AWAITING_PAYMENT: { code: 10, name: 'Awaiting Payment' },

    PENDING: { code: 20, name: 'Pending' },
    PREP: { code: 30, name: 'Prep' },
    BAKE: { code: 40, name: 'Bake' },
    BOX: { code: 45, name: 'Boxing Up' },

    READY_FOR_COLLECTION: { code: 60, name: 'Ready For Collection' },
    READY_FOR_DELIVERY: { code: 65, name: 'Ready for Delivery' },
    OUT_FOR_DELIVERY: { code: 67, name: 'Out For Delivery' },

    // Completion phases, anything >= 70
    DELIVERED: { code: 70, name: 'Delivered' },
    COLLECTED: { code: 80, name: 'Collected' },

    // Complete
    COMPLETE: { code: 100, name: 'Complete' },

    // Temporary Failures
    DRIVER_UNAVAILABLE: { code: 25, name: 'Driver Unavailable' },
    INVALID_ADDRESS: { code: 19, name: 'Invalid Address' },

    // Permanent failures
    OUT_OF_HOURS: { code: -20, name: 'Out Of Hours' },
    OUT_OF_RANGE: { code: -30, name: 'Out Of Range' },
    FAILED_PAYMENT: { code: -50, name: 'Failed Payment' },

    /** @deprecated */
    REFUNDED: { code: -70, name: 'Refunded' },

    CANCELLED: { code: -100, name: 'Cancelled' },

};


/**
 * Current contents of a basket of items that have not been ordered
 *
 * document is stored in the baskets collection using the user id as document key
 *
 * The order lifecycle is basket > new order > order
 *
 * - Users add stuff to the basket
 * - When required the UI can ask the server to validate the basket, this returns a basket that has been checked against the EPOS system
 * - checkout() creates a new order in the orders table and returns the ID
 * - The user selects orderType (D/C) and requestedTime and address/notes
 * - The pay() endpoint requires final details to complete order and returns the payment ref
 * - UI then directs user to payments processor using returned key
 * - The payments gateway then send a webhook to confirm successful payment which will then change the status of the order to pending and add the transaction to the EPOS system.
 *
 */


/** Sent by UI to endpoint to validate basket contents and get totals / discounts etc */
export type TValidateBasketRequest = {

    /** If this is a kiosk, add the kioskId for validation */
    kioskId?: string;

    /** If order is eat in (only available on instore purchases) */
    eatIn?: true,

    /** User Location, approximate area for user delivery */
    userLocation: string;

    /** Location (restaurant) that will receive the order */
    locationPath: FirestorePath;

    /**
     * Till can apply a % discount to the entire basket, it is only applied on items, not on deals.
     * Recorded as a decimal, 1 = 100%
     */
    discountRate?: number;

    /** List of items included in the order */
    items: Array<TValidateBasketRequestItem>;

    /** List of deals included in the order */
    deals?: Array<TValidateBasketRequestDeal>;

    /**
     * System the order originated from, optional as this was added post release
     * This differed from KIOSK | ONLINE due to potential side effects
     */
    originV2?: TOriginV2;

    /**
     * Version of app used to post request, if appVersion is less than required the
     * request will fail with a message to update app to latest version
     */
    appVersion?: string;

};

export type TValidateBasketRequestDeal = {

    /** categoryId for the deal */
    dealId: string;

    /** Items included in the deal */
    items: TValidateBasketRequestDealItem[];

};

/** Requested item added to basket */
export type TValidateBasketRequestItem = {

    /** Master Product Id (in products collection) */
    productId: string;

    /** Name of Product */
    productName: string;

    /** Image of product for display on kiosk */
    productImageUrl?: string;

    /** Number of items required */
    quantity: number;

    /** Extra Toppings etc as listed in multipleChoiceItems */
    extras?: TValidateBasketRequestItem[];

    /** Standard Toppings that can be removed */
    remove?: TRemovableTopping[];

    /** The id of the menu the item belongs to */
    menuId?: string;

};

/**
 * Deal Structure
 */
export type TValidateBasketRequestDealItem = TValidateBasketRequestItem & {

    // Index of the sub category
    categoryIndex: number;

};



/**
 * Contains enriched data from EPOS system to validate basket contents and add taxes
 */
export type TValidatedBasket = {

    /** If order is eat in (only available on instore purchases) */
    eatIn?: boolean,

    /**
     * Delivery charge details
     * Vatable and included in `totalAmount` and `totalTax`
     */
    deliveryCharge?: TDeliveryCharge;

    /** If set, represents discount rate applied to basket */
    discountRate?: number;

    /** If set, represents the discount amount applied to get to totalAmount */
    discount?: number;

    /** Total amount due for payment, _includes_ tax, net of any discount */
    totalAmount: number;

    /** Total amount charged for deals */
    dealAmount?: number;

    /**
     * Total amount customer has paid for a driver tip
     * @deprecated - NOT USED
     */
    tipAmount?: number;

    /** Total tax due for all items */
    totalTax: number;

    /** @deprecated */
    taxes: [];

    /** Validated items */
    items: TValidatedBasketItem[];

    /** Validated Deals */
    deals: TValidatedBasketDeal[];

    /**
     * Products ordered, used by internal systems to
     * display the combined list of products ordered in deals & items
     */
    allProducts: TValidatedBasketItem[];

    /** If the user has used their free pizza in the basket record it here */
    includesFreePizza?: boolean;

};


/** TProduct values to copy to TValidatedBasketItem, used by the restaurant app */
type BasketItemProductDetails = Required<Pick<TProduct, 'ovenMultiple' | 'toppingScreen' | 'pizziolaScreen' | 'prepTime' | 'bakeTime' | 'boxingTime' | 'boxingLabel' | 'hasInstructions'>>;

/** Validated item returned system */
export type TValidatedBasketItem = BasketItemProductDetails & {

    /** Id of product (in products collection) */
    productId: string;

    /** Name of product */
    productName: string;

    /** ImageUrl for product */
    productUrl: string | null;

    /** Number of items required */
    quantity: number;

    /** Price per unit */
    unitPrice: number;

    /** % Discount */
    discountRate?: number;

    /** Discount, used when a % discount has been applied to the whoel basket only. Not used for deals! */
    discountAmount?: number;

    /** Total tax amount for item */
    taxAmount: number;

    /** Tax code */
    taxType: string | null;

    /** Account to book sale to in Xero */
    accountId: string | null;

    /** Total price for item, inclusive of tax (quantity * unitPrice) + sum(extras), net of discounts */
    totalAmount: number;

    /** List of extras required for item */
    extras: TValidatedExtraItem[];

    /** Standard Toppings that have been removed */
    remove?: TRemovableTopping[];

    /** Value of base product without extras */
    baseAmount: number;

    /** Value of extras */
    extrasAmount: number;

    /** This item is the firstPizzaFree deal */
    firstPizzaFree?: boolean;

    /**
     * Value to be recorded in the finance system, this is used to allow for allocation of price to deals,
     * For example on a two for one deal, the totalAmount property shows the pre deal price,
     * The financeTotalAmount is the amount to record from a finance perspective where the deal price is pro rated
     * across the various components.
     *
     */
    financeTotalAmount: number;

    /**
     * As per financeTotalAmount, the allocated tax is the amount to record in the finance system based upon pro rating the deal across the deal components
     */
    financeTaxAmount: number;

    /** The id of the menu the item belongs to */
    menuId?: string;

    /** Product is listed as collection only */
    collectionOnly?: TProduct['collectionOnly'];

    doughBallSize?: TProduct['doughBallSize'];

};

/**
 * A deal consists of multiple TValidatedBasketItem at a discounted price
 *
 */
export type TValidatedBasketDeal = {

    /** Corresponds to the menu category for the deal */
    dealId: string;

    /** If deal is configured for collection only */
    collectionOnly: boolean;

    /** Name of the deal */
    dealName: string;

    /** Combined price for the deal */
    totalAmount: number;

    /** Combined tax amount for the deal */
    taxAmount: number;

    /** Name of tax for the taxAmount field */
    taxType: string | null;

    /** Array of items that are included in the deal, NOTE the prices are PRE-DISCOUNT do not display prices to the user */
    items: TValidatedBasketItem[];

};

/** Subset of TValidatedBasketItem used for extras */
export type TValidatedExtraItem = Pick<TValidatedBasketItem, 'productName' | 'productId' | 'quantity' | 'unitPrice' | 'totalAmount'>;

/** Basket created by system after client validate basket call, used as start point for the creation of an order */
export type TBasket = {

    /** Date basket was created */
    created: Timestamp;

    /** Date basket was last updated */
    updated: Timestamp;

    /** User Location, approximate area for user delivery */
    userLocation: string;

    /** Location (restaurant) that will receive the order */
    locationRef: DocumentReference<TLocation>;

    /** Items as supplied on the IValidateBasketRequest, this is untouched to allow user to alter basket before submission */
    items: TValidateBasketRequestItem[];

    /** Deals as supplied on the IValidateBasketRequest, this is untouched to allow user to alter basket before submission */
    deals: TValidateBasketRequestDeal[];

    /** Basket as validated by EPOS system */
    validatedBasket: TValidatedBasket;

    /** If checkout has commenced, this will show the id of the associated order */
    orderId?: string;

    /**
     * System the order originated from, optional as this was added post release
     * This differed from KIOSK | ONLINE due to potential side effects
     */
    originV2?: TOriginV2;

    /**
     * If appId is included in context (from AppCheck) store it on the record
     */
    appId?: string;

};

export type TOriginV2 = 'till' | 'kiosk' | 'web' | 'app' | 'phone';

/** Requested item added to basket */
export type TBasketItem = {

    /** Reference to productId in products collection */
    productId: string;

    /** Name of Product */
    productName: string;

    /** Number of items required */
    quantity: number;

    /** Extra Toppings etc as listed in multipleChoiceItems */
    extras?: TBasketItem[];

};

/** Minimum required data to create an order in preparation for checkout */
export type TNewOrder = {

    /** Used to aid queries for orders that have been paid for */
    isPendingOrGreater: boolean;

    /** Location (restaurant) that will receive the order */
    locationRef: DocumentReference<TLocation>;

    /** XeroId for location */
    locationXeroId: string;

    /**
     * Current status of Order, follows lifecycle of order from creation to delivery
     * NOTE: This should only usually be set using the Order.setStatus method
     */
    status: IOrderStatus;

    /** Basket as validated by EPOS system */
    validatedBasket: TValidatedBasket;

    /** Items list, used to enable search in elastic app search */
    itemList: string;

    /** Time order was created */
    createdTime: Timestamp | Date;

    /** Date order was created */
    createdDate: Timestamp | Date;

    /** Origin of order */
    origin: TOrderOrigin;

    /** More detailed origin */
    originV2?: TOriginV2;

    /** User associated with order */
    userRef: DocumentReference<TUser>;

    /** Enriched Data: Name of Restaurant the order will be prepared by */
    locationName: string;

    /** Enriched Data: GeoPoint of Restaurant the order will be prepared by */
    locationGeoPoint: GeoPoint;

    /** Define the amount of space this will use in the oven, used by the scheduling algorithm */
    ovenMultiple: number;

    /** Total duration in seconds it will take to prepare all pizza, this is a sum of the prep for each pizza */
    toppingDuration: number;

    /** Total duration in seconds it will take to bake all pizza. This is represented by the longest time it will take to bake a single pizza */
    bakeDuration: number;

    /** Total duration in seconds it will take to box all pizzas. This is repesented by the boxingDuration * count of items where bakeDuration > 0 */
    boxingDuration: number;

    /** Total duration to top, bake & box */
    totalPrepDuration: number;

    /** Times order transitioned between states */
    stateChangeTimes: Partial<Record<TOrderStatus, Date | Timestamp>>;

    /** Kiosk the order was received on */
    kioskId: string | null;

    /** Till the order was created on */
    tillId?: string | null;

    /** Collection slots that were calcualted as available at time of order creation */
    collectionSlots?: TAvailabilitySlot[];

    /** Delivery slots that were calcualted as available at time of order creation */
    deliverySlots?: TAvailabilitySlot[];

    /** If there are not collection slots, display this message */
    collectionSlotsMessage?: TCheckoutResponse['collectionSlotsMessage'];

    /** If there are not delivery slots, display this message */
    deliverySlotsMessage?: TCheckoutResponse['deliverySlotsMessage'];

    /** Time basket was created */
    basketCreated?: Timestamp;

    /** Time basket was updated */
    basketUpdated?: Timestamp;

    /** Optionally store appId from AppCheck on order */
    appId?: string;

};

/** Score assigned by customer */
export type TRateMyPizza = {
    score: number;
    comments?: string;
};


/** Standard Order interface, can be extended to introduce different order types with extra functionality */
export type TOrder = TNewOrder & {

    /** Human friendly identifier for the order */
    orderNumber: string;

    /** Defines how order will be fulfilled, either DELIVERY or COLLECTION */
    orderType: TOrderType;

    /** Name of customer, auto populated for logged in user, or manually filled in for guest */
    customerName: string;

    /** Email of customer, auto populated for logged in user, or manually filled in for guest */
    customerEmail?: string | null;

    /** Phone of customer, auto populated for logged in user, or manually filled in for guest */
    customerPhone?: string | null;

    /** Requested delivery or collection time */
    requestedTime: Timestamp | Date;

    /** Handles archiving of historic orders to improve firestore query performance */
    archived?: true;

    /** Copy of basket validated by downstream finance systems */
    validatedBasket: TValidatedBasket;

    /** Estimated Timings as specified by the order Scheduler */
    estimatedTimings?: TOrderEstimatedTimings;

    /**
     * Difference in minutes between requested time and actual time of completion
     * 0 = within window
     * >0 = after end of window
     * <0 = before start of window
     */
    fulfilmentDelta?: number;

    /** Original Estimated Timings as specified by the order Scheduler */
    originalTimings?: TOrderEstimatedTimings;

    /** The priority of the order for preparation, used to identify which order needs to be processed next, lower the most important */
    schedulerPriority: number | null;

    /**
     * @deprecated
     * Score & comments assigned by the customer for the pizza */
    rateMyPizza?: TRateMyPizza;

    /** Payment Request Reference */
    paymentKey?: string | null;

    /** Payment system customerId */
    customerId?: string | null;

    /** Payment ID in payments system */
    paymentId?: string | null;

    /** Timestamp for time order payment was made */
    paymentTimestamp?: Timestamp;

    /** id for the document  */
    xeroInvoiceId: string | null;

    /** Full stripe payment details */
    payment?: WebhookPayload;

    /** If refunds have been applied to the order, save the details here */
    refunds?: Array<Refund>;

    /** If refunds have been applied to the order, save the total amount refunded here */
    refundAmount?: number;

    /** Record if labels have been printed */
    labelsPrinted?: boolean;

    /** Optional tip, applied after delivery */
    tip?: TOrderTip;

    /** Transaction fees for successful payments */
    transactionFees?: BalanceTransaction;

};

/**
 * Type of tip to be set
 */
export type TTipValueType = 'percentage' | 'amount';

/**
 * Tip types that will be displayed to a user when they decide to tip a driver
 */
export type TTipType = {

    /** Either a percentage relative to 1 (0.05 = 5%) or an amount (1 = £1) */
    type: TTipValueType;

    /** Tip value */
    value: number;

};

/**
 * Post payment tip details, handled as a separate transaction with Stripe
 */
export type TOrderTip = {

    /** Tip awarded by customer */
    amount: number;

    /** Card transaction fees */
    processorFees: number;

    /** Stripe paymentIntent details */
    paymentIntent: PaymentIntent;

};


export type TGeocodeData = {
    results: Result[];
    status: string;
};

export interface Result {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: Geometry;
    place_id: string;
    types: string[];
}

export interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface Geometry {
    bounds: Bounds;
    location: Location;
    location_type: string;
    viewport: Bounds;
}

export interface Bounds {
    northeast: Location;
    southwest: Location;
}

export interface Location {
    lat: number;
    lng: number;
}

/** Options for max delivery range */
export type TDeliveryRangeOptions = 'maxDelivery' | 'maxInRange';

/** Details for delivery charge */
export type TDeliveryCharge = Pick<TValidatedBasketItem, 'taxAmount' | 'taxType' | 'totalAmount' | 'accountId'>;

/** Default settings for delivery charges */
export type TDefaultDeliveryChargeSettings = Pick<TDeliveryCharge, 'taxType' | 'totalAmount' | 'accountId'>;

/** Type for DeliverySettings class */
export type TDeliverySettings = {

    /** Maximum delivery time in seconds */
    maxDeliveryDuration: number;

    /** Range to display in range restaurant */
    maxInRangeDuration: number;

    /** Delivery charge settings */
    deliveryCharge: TDefaultDeliveryChargeSettings;

};

export type TDirectionsData = any;

export type TDeliveryOrderEstimatedTimings = {

    /** Text representation of duration for delivery */
    deliveryDuration: string;

    /** Estimated delivery in seconds */
    deliverySeconds: number;

    /** Estimated return journey in seconds */
    returnJourneySeconds: number;

    /** Estimated time order will be delivered */
    deliveryTime?: Timestamp | Date | null;

    /** Estimated time return journey to base will start */
    commenceReturnJourney?: Timestamp | Date | null;

    /** Estimated time driver will have returned in time for next delivery */
    returnToBase?: Timestamp | Date | null;

};


/** Delivery address for pizza orders */
export type TDeliveryAddress = {
    address1: string;
    address2?: string;
    city: string;
    postcode: string;
};


/** Additional properties required to build a delivery order */
export type TDeliveryOrder = TOrder & {

    address: TDeliveryAddress;

    /** Notes to attach to order, used for giving driver instructions, can be saved against user profile */
    notes?: string;

    /** Name of the  allocated driver */
    driverRef?: DocumentReference | null;

    /** Name/Phone/Availability of the  allocated driver */
    driver?: Pick<TDriver, 'displayName' | 'phone' | 'photoUrl' | 'availability'> | null;

    /** For delivery orders, displays the last known location of the driver */
    driversCurrentLocation?: TCurrentLocation;

    /** Location Data returned about the orders location */
    locationData: TPostCodeResponse;

    /** Data from google maps geocoding service */
    geocodeData: GeocodeResponseData;

    /** Delivery Route information */
    deliveryRoute: TDirectionsData;

    /** Delivery return route information */
    returnJourneyRoute: TDirectionsData;


    /** Text representation of distance for delivery */
    distance: string;

    /** Geocode coordinates for start point */
    deliveryStartGeoPoint: GeoPoint;

    /** Geocode coordinates for end point */
    deliveryEndGeoPoint: GeoPoint;

    /** All estimated delivery related timings */
    estimatedDeliveryTimings: TDeliveryOrderEstimatedTimings;

    /** All original estimated delivery related timings */
    originalTimings?: TOrder['originalTimings'] & TDeliveryOrderEstimatedTimings;

};


/** Times stored by the delivery scheduling algorithms */
export type TOrderEstimatedTimings = {

    prep: Timestamp | Date | null;
    bake: Timestamp | Date | null;
    box: Timestamp | Date | null;
    ready: Timestamp | Date | null;

    /** The 10 minute timeslot that the order will be fulfilled */
    completionWindow: [Timestamp | Date | null, Timestamp | Date | null],

    /** Used by the scheduler to prevent concurrency issues */
    updated: Timestamp | Date | null

    /**
     * Minutes difference to requested time
     * < 0 means the order is expected to be fulfilled _before_ the requested window
     * \> 0 means the order is expected to be fulfilled _after_ the requested window
     */
    fulfilmentDeltaMinutes: number | null;


};


export type TDriverStatus = 'OFF DUTY' | 'ON BREAK' | 'ACTIVE' | 'ON DELIVERY' | 'RETURNING';


export type TModeOfTransport = "eBike" | "moped" | "car" | null;

export type TShift = {

    /** Start time of shift */
    start: Date;

    /** End time of shift */
    end: Date;

    /** Maximum order size a driver can be allocated */
    capacity: number;

    /** Mode of transport */
    mode: TModeOfTransport;

};

/** Driver information */
export type TDriver = Pick<TUser, 'displayName' | 'photoUrl'> & {

    phone: string;

    status?: TDriverStatus;

    workingHours?: Array<TShift>;

    /** Reserved Deliveries */
    availability: TDriverAvailability;

    /** Driver has been allocated for these active orders, immutable */
    allocatedDeliveries: Array<TDriverDelivery>,

    /** URL used on driver app to open the google maps directions screen */
    directionsUrl?: string;

};

export type TDriverAvailability = {

    /** Time driver is available from */
    from: Date | null;

    /** Time driver is available to */
    to: Date | null;

    /** Max ovenMultiple driver is able to take */
    capacity: number;

    /** Driver has been reserved for these pending orders, mutable */
    reservedDeliveries: Array<TDriverDelivery>,

    /** Mode of transport */
    mode: TModeOfTransport;

};

export type TDriverDelivery<T = Date | Timestamp> = {
    orderRef: DocumentReference<TOrder>;
    orderStatus: IOrderStatus;
    deliveryStart: T;
    deliveryEnd: T;
    mode: TStaffRota['mode'];
};

/** Status of availability slot, used to provide information to consumer on considerations during slot selection, multiple status can be displayed */
export enum AvailabilityStatus {

    /** Standard delivery, nothing to show consumer */
    STANDARD = 'S',

    /** Not many slots available, book fast */
    LOW_AVAILABILITY = 'L',

    /** There are other deliveries in your area so your delivery would have lower carbon emmissions */
    GREEN = 'G',

}

/** Response to user request to checkout a basket */
export type ISODateString = string;

export type TAvailabilitySlot = {

    /** Max number of pizzas that can be processed in timeslot */
    capacity: number;

    /** Current number of pizzas booked for timeslot */
    utilised: number;

    /** Current number of pizzas available */
    available: number;

    /** Percent of capacity utilised */
    percentUtilised: number;

    /** Timeslot start time */
    from: ISODateString;

    /** Timeslot end time */
    to: ISODateString;

    /** Timeslot properties */
    status: AvailabilityStatus[];

    /**
     * Price for delivery
     * Only applicable to delivery slots
     */
    deliveryPrice?: number;

};

/**
 * A public version of the availability slot, removes information that should not be shared with the client app
 * TODO: Is this used??
 */
export type TAvailabilitySlotPublic = Pick<TAvailabilitySlot, 'from' | 'to' | 'status'>;


/** Conditonal type to allow DocumentReferences to be deleted from a Document */
export type Deletable<T> = {
    [P in keyof T]: T[P] extends DocumentReference | undefined ? DocumentReference | FieldValue : T[P]
};


/** Permissions the user has been assigned as well as locations they can view */
export type TUserPermissions = {

    /** Users primary location reference, the store they work for! */
    locationRef: DocumentReference;

    /** Users primary location, the store they work for! */
    locationId: string;

    /** List of locations a user is permitted to view (array of locationId) */
    locations: string[];

    /** List of roles the user is permitted for */
    roles: TRoles[];

    /** Conditional employee id */
    employeeId?: number;

    /** Conditional pin code for employee */
    pin?: number;

};

/** Custom claims attached to user auth token */
export type TUserCustomClaims = {

    // List of restaurants a user is permitted to view
    readonly locations?: string[];

    // List of roles the user is permitted for
    readonly roles?: Partial<Record<TRoles, true>>;

};

/** Service location, usually a restaurant */
export type TLocation = {

    /**
     * Name of location, display on table & dialog
     * Table should be filterable by this value
     */
    name: string;

    /**
     * Postcode of location, display on table & dialog
     */
    postcode: string;

    /**
     * Geopoint for location, display on table and dialog
     * (Render as 0.000,0.000)
     * Display on dialog as a value, and also display a google map with an icon at the location
     */
    geoPoint: GeoPoint;

    /**
     * Geohash for location
     * Used for geospatial queries
     */
    geohash: string;

    /**
     * First line of address, display on dialog
     * On table display as a concatenated string with the other address values...
     * chain([address1, address2, town, county, postcode]).compact().join().value()
     */
    address1: string;

    /** Second line of address, display on dialog */
    address2?: string;

    /**
     * Town, display on dialog
     */
    town: string;

    /**
     * County, display on dialog
     */
    county?: string;

    /**
     * Do not display
     */
    driverSchedule?: TDriverSchedule[];

    /**
     * Do not display
     */
    timeSlotSchedule?: TTimeSlotSchedule[];

    /**
     * Max pizza that can be cooked in the designated timeslot
     */
    maxPizzaPerTimeSlot: number;

    /** Photo of restaurant for display in the app */
    imageUrl?: string;

    /** Array of standard opening hours */
    openingHours?: Array<TOpeningHours>;

    /** Adhoc dates the restaurant is closed */
    closedOn?: Array<TLocationClosedOn>;

    /**
     * Enable Collections, if false, checkout will return no slots
     * If value is undefined, defaults to true
     */
    enableCollections: boolean;

    /**
     * Enable Deliveries, if false, checkout will return no slots
     * If value is undefined, defaults to true
     * If enableCollections is false, this will also disable deliveries
     */
    enableDeliveries: boolean;

    /**
     * Restaurant is CPU, if true it deals with incoming orders from other restaurants
     * If value is undefined, defaults to false
     */
    isCpu: boolean;

    /**
     * Supplier id for the assigned CPU.
     * If isCPU is true, it shows which supplier it represents
     * If isCPU is false, it shows the supplier CPU products are ordered from
     * CPU selected from purchase order page and only corresponding CPU gets order
     */
    cpuSupplierId?: string;

    /**
     * If an order is below this value, do not offer delivery slots
     */
    minimumDeliveryValue: number

    /** Xero ContactId */
    xeroContactId?: string;

    /** Xero Contact */
    xeroContact?: TContact;

    /** Xero Refresh Token */
    xeroRefreshToken?: string;

    /** Xero Tenant Id */
    xeroTenantId?: string;

};

/**
 * Dates a location is closed
 */
export type TLocationClosedOn = {
    date: Timestamp | Date;
    description: string;
};

/** Schedule of all pending orders for delivery */
export type TDriverSchedule = {
    name: string;
    id: string;
    from: string;
    to: string;
};

/** Schedule of all pending orders and their impact on oven capacity */
export type TTimeSlotSchedule = {

    /** ISO string time slot starts */
    from: string;

    /** ISO string time slot ends */
    to: string;

    /** % oven utilisation in slot */
    ovenUtilisation: number;

    /** Total orders for delivery */
    deliveryOrderCount: number;

    /** Total orders for collection */
    collectionOrderCount: number;

    /** Total orders */
    totalOrders: number;

    /** Total number of items to display on toppings screen, equates to total pizzas */
    totalToppingScreen: number;

};


/** Service location, usually a restaurant */
export type TLocationResponse = {

    path: FirestorePath;
    name: string;
    postcode: string;

    readonly locationInfo: TPostCodeResponse;
    readonly geoPoint: GeoPoint;

    address1?: string;
    address2?: string;
    town?: string;
    county?: string;

};

export type TPostcodesByLatLngResponse = {
    status: number;
    result: Array<TPostCodeResponse & { distance: number }>;
};



/** Postcode geodata from postcodes.io */
export type TPostCodeResponse = {
    postcode: string;
    quality: number;
    eastings: number;
    northings: number;
    country: string;
    nhs_ha: string;
    longitude: number;
    latitude: number;
    european_electoral_region: string;
    primary_care_trust: string;
    region: string;
    lsoa: string;
    msoa: string;
    incode: string;
    outcode: string;
    parliamentary_constituency: string;
    admin_district: string;
    parish: string;
    admin_county: string;
    admin_ward: string;
    ced: string;
    ccg: string;
    nuts: string;
    codes: {
        admin_district: string;
        admin_county: string;
        admin_ward: string;
        parish: string;
        parliamentary_constituency: string;
        ccg: string;
        ccg_id: string;
        ced: string;
        nuts: string;
        lsoa: string;
        msoa: string;
        lau2: string;
    };
};


/** Location Cordinates, lat & long */
export type TLatLng = [number, number];

export type days = 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';

export type TOpeningHours = {

    /** Day of the week */
    day: days

    /** HH:MM in local time */
    from: string;

    /** HH:MM in local time*/
    to: string;

};

/** Returns location information for a location that has been requested in the available locations call */
export type TInRangeResponse = {

    estimatedDuration: {
        /** indicates the duration in seconds. */
        value: number;
        /** contains a human-readable representation of the duration. */
        text: string;
    };

    /** The google maps derived coordinates to destination */
    destinationCoords: TLatLng,

    /** Location (restaurant) details */
    location: {
        name: string;
        path: FirestorePath;
        latLong: TLatLng;
        phone?: string | null;
        address1?: string | null;
        address2?: string | null;
        postcode?: string | null;
        town?: string | null;
        imageUrl?: string;
        openingHours?: Array<TOpeningHours>;
        closedOn?: Array<TLocationClosedOn>;
    };
};


export type TAddStaffResponse = {
    uid: string;
};


/** A list of endpoints available via the onCall method on the firebase SDK */
export interface IHttpEndpoints {

    /**
     * Get a list of locations that can deliver based upon the X minute delivery window and supplied postcode
     * destination can be a string (usually a postcode or a tuple with lat/lng)
     */
    getInRangeLocations({ destination, rangeOption }: { destination: string | TLatLng, rangeOption?: TDeliveryRangeOptions }): Promise<TInRangeResponse[]>;

    /** Add a new staff member and create / link to User */
    addStaff(staffRecord: TNewStaffRequest): Promise<TAddStaffResponse | void>;

    /** Validate the contents of the basket document for the user */
    validateBasket(basket: TValidateBasketRequest): Promise<TValidatedBasket>;

    /** Clear the contents of the basket document for the user */
    clearBasket({ kioskId }?: { kioskId?: string }): Promise<void>;

    /** Checkout, for user checkout basket is keyed to userId, for kiosk checkout provide the kioskid */
    checkout({ kioskId, showDeliverySlots }: { kioskId?: string | null, showDeliverySlots?: boolean }): Promise<TCheckoutResponse>;

    /** Pay for order */
    pay(args: TPayOptions): Promise<TPayResponse>;

    /** Refund an order */
    refund(args: TRefundOptions): Promise<TRefundResponse>;

    /** Update Order Status, user requires orderManagement permission to use this endpoint */
    updateOrderStatus(status: TOrderStatusOptions): Promise<void>;

    /** Set driver tip for an order */
    addDriverTip(options: TDriverTipArgs): Promise<void>;

    /** Endpoint to allow admins to update daily checklist summary on demand */
    updateDailyChecklistSummaryManually(): Promise<void>;

    /** onCall endpoint to allow admins to create daily checklists on demand */
    createDailyChecklistsManually(): Promise<void>;

    /** Sync allergens for product */
    resyncProductsToMenu(): Promise<void>;

    /** Create new stock take document */
    createStockTake({ locationId }: { locationId: string }): Promise<string | void>;

    /** Sync allergens for product */
    syncAllergens({ productId }: { productId?: string }): Promise<string | void>;

    /** Update cost of a product */
    updateProductCost({ updateAll, productId }: { updateAll: boolean, productId?: string }): Promise<void>;

    /** Resend Xero Batch */
    resendXeroBatch({ batchId }: { batchId: string }): Promise<string | void>;

    /** Reset batch and resend */
    removeAndRedoXeroBatch({ batchId }: { batchId: string }): Promise<void>;

    /** Returns uri to authenticate xero */
    getXeroAuthUri({ locationId }: { locationId?: string }): Promise<string | void>;

    /** Dispatch a CPU order. If `partialDispatch` is not undefined then only the specified product will
    be dispatched */
    dispatchCpuOrder({ orderId, cpuLocationId, partialDispatch }: { orderId: string, cpuLocationId: string, partialDispatch?: { productId: string } }): Promise<void>;

    /** Process a clock in or clock out event */
    clockInClockOut({ locationId, employeeId, pin }: { locationId: string, employeeId: number, pin: number }): Promise<string | void>;

    /**
     * Request server to generate an order from the users current basket
     * @returns {string} orderId for the new order
     */
    getStripeConnectionToken({ serialNumber }: { serialNumber: string }): Promise<string | void>;

    /** Create product movements for CPU batch */
    makeBatch({ locationId, productId, batchAmount }: { locationId: string, productId: string, batchAmount: number }): Promise<void>;

    /** Submit a stock take document */
    submitStockTake({ stockTakeId }: { stockTakeId: string }): Promise<void>;

    /** Create a receipt and a stock movement for a purchase order product */
    createPurchaseOrderReceipt(receipt: TPurchaseOrderReceipt): Promise<void>;

    /** Create new purchase order */
    createPurchaseOrder({ locationId, supplierId }: { locationId: string, supplierId: string }): Promise<string | void>;

    /** onCall endpoint to get all suppliers in products table that are unique */
    getSuppliersInProductsTable(): Promise<TProductSupplier[] | void>;

    /** Submit a purchase order document */
    submitPurchaseOrder({ purchaseOrderId }: { purchaseOrderId: string }): Promise<void>;

    /** Complete purchase order. Used for purchase orders that will never be received */
    completePurchaseOrder({ purchaseOrderId }: { purchaseOrderId: string }): Promise<string | void>;

    /**
     * Request server to generate an order from the users current basket
     * @returns {string} orderId for the new order
     */
    capturePaymentIntent(paymentIntentId: string): Promise<void>;

    /** Backfill transaction fees for all orders from `fromDate` */
    backfillOrderTransactionFees({ fromDate }: { fromDate: string }): Promise<{
        total: number;
        success: number;
        failure: number;
        noop: number;
    } | void>;

    /** Send all stock movements from `fromDate` to elastic search */
    indexStockMovements({ fromDate }: { fromDate: string }): Promise<void>;

    /** Send all abandoned baskets from `fromDate` to elastic search */
    indexAbandonedBaskets({ fromDate }: { fromDate: string }): Promise<void>;

    /** Backfill all orders from `fromDate` and update profitAndLoss data */
    calculateOrderProfitAndLoss({ fromDate }: { fromDate: string }): Promise<void>;

    /** Add xero authentication credentials to a location */
    saveXeroCredentials({ locationId, xeroRefreshToken, xeroTenantId, tenant }: { locationId?: string, xeroRefreshToken: string, xeroTenantId: string, tenant: string }): Promise<string | void>;

    /** Restart the kiosk matching the deviceId remotely */
    remoteRestartKiosk({ serialNumber }: { serialNumber: string }): Promise<void>;

}

/**
 * Properties required for adriver tip
 */
export type TDriverTipArgs = {

    /** Order ID for order to apply a tip to */
    orderId: string;

    /** Amount (in pounds & pence, 2 decimals max) to tip driver */
    tipAmount: number;

};

/** Arguments required to complete a checkout in preparation for payment */
export type TPayOptions = {

    /** ID for order */
    orderPath: FirestorePath;

    /** Whether this is Delivery or Collection */
    orderType: TOrderType;

    /** The time the order is to be collected or delivered, Date or ISO date */
    requestedTime: Date;

    /** Customer Name */
    customerName: string;

    /** Customer phone number, required if email is empty */
    customerPhone?: string;

    /** Customer email, required if phone is empty */
    customerEmail?: string;

    /** If order type is Delivery, this is required */
    address?: TDeliveryAddress;

    /** Notes to attach to order, used for giving driver instructions, can be saved against user profile */
    notes?: string;

    /** isKiosk is used to allow stripe to follow card_present order flow  */
    isKiosk?: boolean;

    /** isKiosk is used to allow is to generate orders from the till, changes the order code prefix to T  */
    isTill?: boolean;

    /** If this is a pay by phone (MOTO) order, capture the card details here */
    cardDetails?: {
        cardNumber: string;
        expiryMonth: number;
        expiryYear: number;
        cvvCode: string;
    };

    /**
     * If the user wishes to give the driver a tip, supply the amount here
     *
     * Not currently used, tips can only be allocated after delivery at present
     *
     */
    tipAmount?: number;

};

interface TRefundOptionsStandard {

    /** ID for order */
    orderPath: FirestorePath;

    /** Reason for refund */
    reason: Reason;

}

// tslint:disable-next-line: no-empty-interface
export interface TRefundOptions extends TRefundOptionsStandard { }

export interface TRefundOptions extends TRefundOptionsStandard {

    /** Amount to be refunded (if a partial refund) */
    amount: number;

}

export interface TRefundOptions extends TRefundOptionsStandard {

    /** If true, order will be set to the status of cancelled, cannot be set if an amount is set */
    cancelOrder: boolean;

}


/** Response to request to checkout */
export type TCheckoutResponse = {

    /** The orderId for the created order */
    orderPath: FirestorePath;

    /** The timeslots available for delivery or collection, these are displayed to the user on the checkout screen */
    availableSlots: Record<TOrderType, TAvailabilitySlot[]>;

    /** If there are not collection slots, display this message */
    collectionSlotsMessage: string;

    /** If there are not delivery slots, display this message */
    deliverySlotsMessage: string;

};

/** Response to request to checkout */
export type TPayResponse = {

    /** Order ID - only used to allow for mock data testing */
    orderId: string;

    /**
     * Stripe Payments Client Secret
     * If order is zero priced the paymentsKey will be null
     */
    paymentsKey: string | null;

    /**
     * Stripe Payments Client ID
     * If order is zero priced the customerId will be null
     */
    customerId: string | null;

    /**
     * Stripe Payments Client Ephemeral Key
     * ephemeralKeys.create
     * https://com/docs/payments/save-and-reuse?platform=react-native&ui=payment-sheet#react-native-collect-payment-details
     */
    customerKey: string | null;


    /** Order Number, for printing on receipt */
    orderNumber: string;

    /** Order Details */
    order: TOrder;

};

/** Response from requesting a refund */
export type TRefundResponse = {

    /** Refund status */
    status: string;

};

/** Required properties to generate a new staff record */
export type TNewStaffRequest = {

    /** Full Name of Staff Member */
    name: string;

    /** Email address to map to staff record */
    email: string;

    /** Phone number to map to staff record */
    phone: string;

    /** A list of roles that the user should be given */
    roles: TRoles[];

    /** Array of locationIds the user is assigned to */
    locations: string[];

    /** Drivers location reference, the store they work for! */
    primaryLocationId: string;

};


/** Options to supply for updating an order status */
export type TOrderStatusOptions = {

    /** Path of Order to set status on */
    orderPath: FirestorePath;

    /** Code to set the order to */
    status: TOrderStatus;

};

/**
 * Driver location is recorded during delivery and added to a collection for monitoring
 */
export type TDriverLocation = {

    /** Reference of user on location record */
    userRef: DocumentReference;

    /** Timestamp the location was recorded */
    timestamp: Timestamp | Date;

    /** Current recorded location */
    position: GeoPoint;

    /** OrderRef - If the driver is currently delivering an order, the reference for the order being delivered */
    orderRef?: DocumentReference;

    /** All Details recorded from flutter location */
    location: {
        latitude?: number;
        longitude?: number;
        accuracy?: number;
        verticalAccuracy?: number;
        altitude?: number;
        speed?: number;
        speedAccuracy?: number;
        heading?: number;
        time?: number;
        isMock?: boolean;
        headingAccuracy?: number;
        elapsedRealtimeNanos?: number;
        elapsedRealtimeUncertaintyNanos?: number;
        satelliteNumber?: number;
        provider?: string;
    };

};

/** Used to record current location on user & order documents */
export type TCurrentLocation = {
    location: GeoPoint;
    updated: Timestamp | Date;
};

/** Type of message */
export type TPushMessageTypes = 'orderStatus' | 'promotions';

export type TPushMessage = {

    notification: {

        /** Title to be added to notifications section */
        title: string;

        /** Message to be added to notifications section */
        body: string;

    },

    /** Placeholder for all data that might be used */
    data: {};

};

export type TPushMessageOrderStatus = TPushMessage & {

    /** Arbitrary data added to the push message payload */
    data: {
        /**
         * Message type, added to added to data to define android message channel.
         * NB: This may be better to go into the android section of the push definition but for now we will leave in data
         */
        type: 'orderStatus' | 'addTip';

        /** Full path for order */
        orderPath: FirestorePath;

        /** OrderId for document */
        orderId: string;

        /** Used by flutter */
        click_action: string;

    }

};

export type TPushMessageUserDiscount = TPushMessage & {

    /** Arbitrary data added to the push message payload */
    data: {
        /**
         * Message type, added to added to data to define android message channel.
         * NB: This may be better to go into the android section of the push definition but for now we will leave in data
         */
        type: 'userDiscount';

        /** Used by flutter */
        click_action: string;

    }

};

export type TPushMessagePromotion = TPushMessage & {

    /** Arbitrary data added to the push message payload */
    data: {
        /**
         * Message type, added to added to data to define android message channel.
         * NB: This may be better to go into the android section of the push definition but for now we will leave in data
         */
        type: 'promotion';

    }

};

/**  */
// export type TPushMessageOrderStatus = TPushMessage & {

//     data: {


//     }

// };


/**
 * Types of tags
 */
export type TTagTypes = 'allergen';

/** Tags that can be used to classify stuff e.g. allergens */
export type TTags = {
    name: string;
    type: TTagTypes;
    imageUrl?: string;
};


/** Contacts table, synced from Xero, primarily used for supplier info */
export type TContact = {
    contactId: string;
    name: string;
    isCustomer: boolean;
    isSupplier: boolean;
    disabled: boolean;
};

/** Accounts table, synced from Xero, used to allocate products to the correct ledger in Xero */
export type TAccount = {
    accountId: string;
    name: string;
    code: string | null;
    taxType: string | null;
    description: string | null;
    status: Account.StatusEnum | null;
};

/** Accounts table, synced from Xero, used to allocate products to the correct ledger in Xero */
export type TTaxRate = {

    /** Code for tax rate */
    taxType: string;

    /** Name to display on receipt */
    name: string;

    /** Tax rate in percent e.g. 12 = 12% */
    displayTaxRate: number;

    /** Tax rate in percent e.g. 12 = 12% */
    effectiveRate: number;

};


/** Response from end of day batch request */
export type EndOfDayBatch = {

    invoiceNumber?: string;
    totalAmount?: number;
    xeroInvoiceId?: string;
    totalTax?: number;
    sentTimestamp?: Timestamp;

    /** Invoice as created in Xero */
    invoice: Invoice;

    /** Array of all orderId's that the Invoice relates to */
    orderIds: string[];

    /** Batch status */
    status: 'UNSENT' | 'SENDING' | 'SENT' | 'ERROR' | 'CANCELLING' | 'CANCELLED';

};

/**
 * Batch that has been sent to Xero
 */
export type SentEndOfDayBatch = EndOfDayBatch & {
    sentTimestamp: Timestamp;
    xeroInvoiceId: string | null;
    totalAmount: number | null;
    totalTax: number | null;
    invoiceNumber: string | null;
};

/** Invoice spec for purchase order invoice  */
export type PurchaseOrderInvoice = Omit<EndOfDayBatch, 'orderIds'>;

/** Invoice spec for purchase order invoice that has been sent to Xero  */
export type SentPurchaseOrderInvoice = Omit<SentEndOfDayBatch, 'orderIds'>;


/** Seed numbers for orderRef */
export type TOrderRef = {
    T: number;
    K: number;
    D: number;
    C: number;
    P: number;
};


/** Device registered for push messaging */
export type TDevice = {
    uid: string;
    timestamp: Timestamp;
};


/**
 * Kiosk roles
 */
export type TKioskRole = 'customer-orders' | 'till' | 'dough' | 'topping' |
    'pizziola' | 'boxing' | 'collections' | 'menu' | 'clockIn' |
    'entrance' | 'stock-management' | 'stock-locations' | 'topping-instructions' | 'pizza-instructions' |
    'pizza-instructions-topping' | 'pizza-instructions-boxing' | 'staticWindowDisplay' | 'dashboard' | 'animated';

/**
 * Kiosk configuration doc
 */
export type TKiosk = {

    /** Serial Number of kiosk */
    serialNumber: string;

    /** Role kiosk will perform */
    role: TKioskRole;

    /** User friendly name for kiosk */
    name?: string;

    /** Location where kiosk is installed */
    locationId: string;

    /** Used to printer customer receipts and order labels */
    receiptPrinterIp?: string;

    /** Used to connect to stripe card reader */
    cardReaderSerialNumber?: string;

    /** Stock location ids to be displayed on stock location kiosk */
    stockLocations?: string[];

    /** Kiosk config can include any value, make sure you document in code what you add! */
    [key: string]: any;
};


/**
 * Config for Phoenix
 */
export type TPhoenixSettings = {

    /** id of menu to display */
    currentMenu?: 'video' | string;

    /**
     * For some currentMenu types a url is required
     *
     * For example, if currentMenu = 'video' then the url is used to display the media
     *
     */
    url?: string | string[];

    /** Styles for portrait orientation */
    styles?: {
        logoWidth: string;
        nameFontSize: string;
        detailsFontSize: string;
        priceFontSize: string;
        allergenIconSize: string;
        categoryFontSize: string;
    };


    /** Styles for landscape orientation */
    lanscapeStyles?: {
        logoWidth: string;
        nameFontSize: string;
        detailsFontSize: string;
        priceFontSize: string;
        allergenIconSize: string;
        categoryFontSize: string;
    };


    /** Custom message to display at bottom of menu screen */
    menuFooter?: string;

};


export type TPhoenixAllergens = 'gluten' | 'milk' | 'nut' | 'fish' | 'soya' | 'mustard' | 'eggs' | 'treenut' | 'celery';

/**
 * Phoenix Menu Category
 */
export type TPhoenixMenuCategory = {

    /** Category to display in header */
    name: string;

    /** Custom colour for the header */
    headerColour?: string;

    /** Custom colour for the header background */
    headerBgColour?: string;

    items: Array<{
        name: string;
        description: string;
        price: number;
        allergens: TPhoenixAllergens[];
    }>;
};

export type TPheonixExtraToppings = {
    name: string;
    items: string[];
    price: number;
};

/**
 * Phoenix Specific Menu System
 */
export type TPhoenixMenu = {
    name: string;
    category: TPhoenixMenuCategory[];
    extraToppings: TPheonixExtraToppings[];
    columns?: number;
};


/**
 * Define schedule to decide what menu is currently displayed
 *
 * Precedence is set by filtering for all menus that are active at the defined time,
 * if dates &| dayOfWeek are null they are assumes to be active all the time
 *
 * e.g. dates & dayOfWeek are null then menu is active every day from fromTime to toTime but
 * menus on specific dates or daysOfWeek take precedence
 *
 *
 */
export type TPhoenixMenuSchedule = {

    /** Dates active format YYYY-MM-DD if null it is effective every day */
    dates: string[] | null;

    /** Day meny is active if null it is effective every day of the week */
    dayOfWeek: days | null;

    /** There can be multiple menuSlots based upon times,
     * menuSlots should not overlap
     * if toTime < fromTime it is assumed to be the following day
     */
    menuSlots: Array<{

        /** Time menu is active from in HH:MM format */
        fromTime: string;

        /** Time menu is active to in HH:MM format */
        toTime: string;

        /**
         * Menu that is active during this time
         * null means display no menu
         */
        menuId: string | null;

        /**
         * If menuId === 'video' this will display the video panel, enter the urls to play in the urls property
         */
        url?: string[];

    }>;

};

/**
 * Basic information for creating a staff rota record
 */
export type TStaffRota = {

    /** userId for the user */
    staffId: string;

    /** Copied from displayName on user document */
    displayName: string;

    /** locationId for the user, calculated from user.permissions.locationRef.id */
    locationId: string;

    /** Copied from the location document */
    locationName: string;

    /** Timestamp for the start of the shift: NB make sure this isn't impacted by DST changes (use luxon?) */
    fromTime: Timestamp;

    /**
     * Timestamp for the end of the shift: NB make sure this isn't impacted by DST changes (use luxon?)
     * NOTE: toTime must be > fromTime
     */
    toTime: Timestamp;

    /** userId of the user who created the record */
    createdBy: string;

    /** userId of the user who created or updated the record */
    updatedBy: string;

    /** Driver capacity, used to ensure orders larger than X are not allocated to drivers who cannot get them on the bike */
    capacity?: number;

    /** Mode of transport */
    mode: 'moped' | 'car' | 'eBike' | null

};



export interface TStockVolume {
    measurementType: Extract<TMeasureType, 'volume'>;
    measurementUnit: Extract<TMeasure, 'ml' | 'l'>;
}

export interface TStockWeight {
    measurementType: Extract<TMeasureType, 'weight'>;
    measurementUnit: Extract<TMeasure, 'mg' | 'g' | 'kg'>;
}

export interface TStockQty {
    measurementType: Extract<TMeasureType, 'qty'>;
    measurementUnit: never;
}

/**
 * Standardised units, consists of a type (weight, volume or qty)
 *
 * And associated units of measurement for selected type
 * And then the qty of units
 *
 */
export type TStockUnits = (TStockWeight | TStockVolume | TStockQty) & {
    qty: number;

    /**
     * Multiple of purchase units currently in stock
     *
     * e.g. If we have 1400g of a product that is purchased
     * in lots of 1kg we would have 1.4 lots
     *
     */
    qtyInPurchaseUnits?: number;

    /**
     * Multiple of portions per unit currently in stock
     *
     * e.g. If we have 1400g of a product that is purchased
     * in units of 1kg with 2 portions per unit we would
     * have 2.8 portions
     *
     */
    qtyInPortions?: number;

    /**
     * Multiple of units per case currently in stock
     *
     * e.g. If we have 1400g of a product that is purchased
     * in units of 1kg with 2 units per case we would
     * have 0.7 portions
     *
     */
    qtyInCases?: number;
};

interface IProductMovementSale {

    type: 'sale';
    orderId: string;

    /**
     * id of the location the item was attributed to
     */
    fromStorageLocation: RecWithId<{ name: string }> | null;

}

interface IProductMovementPurchaseOrder {
    type: 'purchaseOrder';
    purchaseOrderId: string;
}

interface IProductMovementPurchaseOrderReceipt {

    type: 'purchaseOrderReceipt';
    purchaseOrderId: string;

    /**
     * id of the location the item was attributed to
     */
    toStorageLocation?: RecWithId<{ name: string }> | null;

}

interface IProductMovementStockTake {
    type: 'stockTake';
    stockTakeId: string;
    fromStorageLocation?: RecWithId<{ name: string }>;
}

interface IProductMovementManualAdjustment {
    type: 'manualAdjustment';
    description: string;
    fromStorageLocation?: RecWithId<{ name: string }>;
}

interface IProductMovementTransfer {
    type: 'transfer';
    fromStorageLocation: RecWithId<{ name: string }>;
    toStorageLocation: RecWithId<{ name: string }>;
}

interface IProductMovementWastage {
    type: 'wastage';
    fromStorageLocation: RecWithId<{ name: string }> | null;
}

interface IProductMovementBatchIngredient {
    type: 'batchIngredient';
    fromStorageLocation: RecWithId<{
        name: string;
    }> | null;
}

interface IProductMovementMakeBatch {
    type: 'makeBatch';
}

interface IProductMovementCPUSale {
    type: 'CPUSale';
}

type TMovementTypes = IProductMovementSale |
    IProductMovementPurchaseOrder | IProductMovementPurchaseOrderReceipt | IProductMovementStockTake |
    IProductMovementTransfer | IProductMovementWastage | IProductMovementManualAdjustment |
    IProductMovementBatchIngredient | IProductMovementMakeBatch | IProductMovementCPUSale;

/**
 * Each event that results in a change of stock qty will be recorded here
 */
export type TProductMovement = TMovementTypes & TStockUnits & {

    /** LocationId the movement relates to */
    locationId: string;

    /** productId the movement relates to */
    productId: string;

    /** Snapshot of product at time event occured */
    product: TProduct;

    /** Timestamp when the event occured */
    timestamp: Timestamp | Date;

    /** Optional reason for movement */
    description?: string;

};

export type TProductStockLevel = TStockUnits & {

    productId: string;

    /** Snapshot of product, genrated at time of last stock level update */
    product: TProduct;

    /** Location stock level relates to */
    locationId: string;

    /**
     * Multiple of purchase units currently on order
     *
     * e.g. If we have 2000g of a product that is on order
     * in lots of 1kg we woudl have 2 lots on order
     *
     */
    qtyOrderedInPurchaseUnits?: number;


    /** Grouped by stock location and batch, enables monitoring of shelf life by batch */
    byStockLocationBatch: Array<TStockUnits & {

        /** Current location for batch */
        stockLocation: RecWithId<{ name: string }>;

        /** Time batch was created */
        createdTime: Timestamp | Date;

        /** Number in minutes that a product can remain in current location before being discarded  */
        shelfLife: number;

    }>;

    /** Total units by location */
    byStockLocation: Array<TStockUnits & {
        stockLocation: RecWithId<{ name: string }>;
    }>;

    /**
     * If product is in stock or not
     * Manually controlled from stock level kiosk
     */
    inStock: boolean;

};

/** Names of all locations where stock can be stored */
export type TStockLocation = {
    name: string;
};


/**
 * Base document for stock take collection
 */
export type TStockTake = {

    /** User who created stock take */
    createdByUser: RecWithId<Pick<TUser, 'displayName'>>;

    /** Timestamp for creation */
    timestamp: Timestamp;

    /** Location for Stock Take */
    locationId: string;

    /** Current status of stock take */
    status: 'new' | 'cancelled' | 'submitted';

};

/**
 * Stock Take Stock Level
 */
export type TActualProductStockLevel = TProductStockLevel & {

    /** Time stock level was recorded */
    timestamp: Timestamp;

    /**
     * Actual units, initially undefined
     * New stock level units set in stock take
     */
    actualUnits?: number;

    /**
     * Actual qty, initially undefined
     * `actualUnits * purchaseQty`
     */
    actualQty?: number;

    // TODO: Add difference in units & qty

    /** Stock location id to adjust with actual qty */
    stockLocationId?: string;

    /** Display name to show stock take products with multiple stock locations */
    displayName?: string;

};



export type TPurchaseOrderStatus = 'NEW' | 'SUBMITTED' | 'PROCESSING' | 'PARTIALLY_DISPATCHED' |
    'DISPATCHED' | 'PARTIALLY_RECEIVED' | 'RECEIVED' | 'CANCELLED' | 'COMPLETE';


export type TPurchaseOrder = {

    /** Current status of the PO, users should only be able to set status of NEW, all pther statuses are controlled by the back end */
    status: TPurchaseOrderStatus;

    /** Location for the purchase order */
    locationId: string;

    /** Supplier for the purchase order */
    supplierId: string;

    /** User who created the purchase order */
    createdByUser: RecWithId<Pick<TUser, 'displayName'>>;

    /** Timestamp for order creation time */
    createdTime: Timestamp;

    /** Timestamp for order submitted time  */
    submittedTime?: Timestamp

    /** Location detail for selected location */
    location: Pick<TLocation, 'name' | 'address1' | 'address2' | 'county' | 'town' | 'postcode'>;

    /** Supplier information for selected supplier */
    supplier: Pick<TProductSupplier, 'name' | 'contactId'>;

    /** Date for purchase order to be delivered */
    deliveryDate: Date | Timestamp;

    /** Note for Delivery */
    deliveryNote: string | null;

    /** Xero status of purchase order */
    xeroStatus?: PurchaseOrder | null

};


/** Product details for an item ordered as part of a purhase order */
export type TPurchaseOrderProduct = {

    /** ID of parent purchase order */
    purchaseOrderId: string;

    /** Product Id */
    productId: string;

    /** Product detail copied from product record */
    product: Pick<TProduct, 'name' | 'purchasePrice' | 'purchaseAccount' | 'purchaseQty' | 'purchaseTaxRate' | 'purchaseUnit' | 'unitsPerCase' | 'supplierProductCode' | 'measurementType'>;

    /** Location ID for PO */
    locationId: string;

    /** Supplier ID for PO */
    supplierId: string;

    /** Number of units being ordered */
    orderUnits: number;

    /**
     * Number of cases being ordered
     * orderUnits * product.unitsPerCase
     */
    orderCases: number;

    /** Current recorded stock lebel */
    currentStockLevel: Pick<TProductStockLevel, 'qtyInPurchaseUnits'>;

    /** Number of units received, updated from a trigger monitoring TPurchaseOrderReceipts */
    receivedUnits?: number;

    /** Keep track of dispatched products in a partially dispatched PO */
    dispatched?: boolean;

    /** Timestamp for order submitted time  */
    submittedTime?: Timestamp

};

/** Document recoding the receipt of a product from a purchase order, there can be multiple receipts per product */
export type TPurchaseOrderReceipt = TPurchaseOrderProduct & {

    /** ID of the associated purchase order product document */
    purchaseOrderProductId: string;

    /** Number of units received in this delivery */
    receivedUnits: number;


};

/** Firestore doc staff settings  */
export type TStaffettings = {

    /** Last employee id counter */
    lastEmployeeId: number;
};

/** Settings required for Xero */
export type TXeroSettings = {
    refreshToken: string;
    refreshTokenCreated: Timestamp;
    clientId: string;
    clientSecret: string;
    tenantId: string;
};

/** Document recording the clock-in and clock-out times for a user */
export type TTimesheet = {

    /** User details (from lookup) */
    user: Pick<TUser, 'displayName' | 'phone' | 'email'> & Pick<TUserPermissions, 'employeeId'>;

    /** Location details (from lookup) */
    location: Pick<TLocation, 'name'>;

    /** ID of the user */
    userId: string;

    /** Location of event */
    locationId: string;

    /** Timestamp for clock-in event */
    clockInTimestamp: Timestamp;

    /** Timestamp for clock-out event */
    clockOutTimestamp?: Timestamp;

    /**
     * Time difference in hours
     * 2 decimal points
     * Calculated from firebase function when timesheet record created or updated
     */
    hours?: number;
};

export type TGetAddressIoAddress = {
    building_name: string;
    building_number: string;
    country: string;
    county: string;
    district: string;
    formatted_address: [
        string,
        string,
        string,
        string,
        string,
    ];
    line_1: string;
    line_2: string;
    line_3: string;
    line_4: string;
    locality: string;
    sub_building_name: string;
    sub_building_number: string;
    thoroughfare: string;
    town_or_city: string;
};

export type TGetAddressIoResponse = {
    postcode: string;
    latitude: number;
    longitude: number;
    addresses: TGetAddressIoAddress[];
};

export type TGetInRangeLocations = {

    uid: string | null;

    result: TInRangeResponse[];

    destinationCoords: GeoPoint | null;

    destination: string | TLatLng;

    timestamp: Timestamp;

    appId: string | null;

};

/**
 * This is a category that needs to be specified on a new checklist task
 */
export type TCheckListCategory = {
    /** name of the category */
    name: string;
    /** used to represent the input for AutoComplete component on checklist dialog */
    inputValue?: string;
};

/**
 * This is a template of a task that needs to be performed on a 'frequency'
 */
export type TCheckListTaskTemplate = {

    /** whether we should add the task to the checklist */
    status: 'inactive' | 'active';

    /** name of the task, i.e. clean fridge */
    name: string;

    /** Does the task require an image */
    imageRequired: boolean;

    /** Additional comments regarding the task */
    comments?: string;

    /** how will the task be displayed */
    type: 'checkbox' | 'number' | 'text';

    /** Checklist task category */
    category: string;

    /** If true the checklist task will be creatable on demand as opposed to a set time */
    onDemand: boolean;

    /**
     * Cron string that defines the frequency of the task
     *
     * e.g. to have a task at 12:00 every day: 0 12 * * *
     *
     */
    frequency: string | null;

};

/** Actionable task for a checklist */
export type TCheckListTask = TCheckListTaskTemplate & {

    locationId: string;

    dueDate: Timestamp;

    completedDate: Timestamp | null;

    result: boolean | string | number | null;

    completedByUid?: string | null;

    imageUrl?: string | null;

    name: string;

};

/** Record for out of stock ingredients */
export type TOutOfStock = {

    /** The productId that needs to be marked as out of stock */
    productId: string;

    /** The actual productId that is out of stock */
    ingredientId: string;

    /** The product details of the actual product out of stock */
    product: TProduct;

};

/** Summary of of checklist tasks */
export type TChecklistSummary = {

    locationId: string;

    date: Timestamp;

    taskCount: number;

    completeCount: number;

    incompleteCount: number;

    overdue: number;

};

/** Cost details for a product */
export type TProfitAndLoss = {

    grossCost: number;

    netCost: number;

    grossProfit: number;

    netProfit: number;

};

/** Private details for a product */
export type TPrivateProductDetails = {

    /**
     * Profit and loss details
     * TODO: Potentially rename to profitAndLoss?
     */
    costDetails: TProfitAndLoss | null;

    /**
     * CPU profit and loss details for CPU products
     * TODO: Potentially rename to cpuProfitAndLoss?
     */
    cpuCostDetails: TProfitAndLoss | null;

};

/** P&L record for a product */
export type TProductProfitAndLoss = {

    productId: string;

    product: TProduct;

    profitAndLoss: TProfitAndLoss;

};

/** P&L record that contains cost details for an order */
export type TOrderProfitAndLoss = {

    /** Array of P&L records for each product in the order */
    productsProfitAndLoss: TProductProfitAndLoss[];

    /** Total P&L for order */
    totalProfitAndLoss: TProfitAndLoss;

};

/** Private details for an order */
export type TPrivateOrderDetails = {

    /** Validated basket */
    validatedBasket: TValidatedBasket;

    /** P&L record for order and products in the order */
    orderProfitAndLoss: TOrderProfitAndLoss;

};

/** Basket with its profit and loss data */
export type TAbandonedBasket = TBasket & {
    profitAndLoss: TProfitAndLoss,
    userId: string
};

/** Instruction to make a product */
export type TProductMakeInstruction = {

    order: number;

    instruction: string;

    imageUrl: string;

    phase: 'boxing' | 'topping' | 'prep';

};

/** Discount Rate, both generic & applicable to a user */
export type TDiscountRate = {

    /** Id of the discount */
    id: string;

    /** Name of the discount */
    name: string;

    /** Discount percentage */
    rate: number;

    /** If this discount rate is part of the loyalty scheme, the minimum spend needed to gain it */
    loyaltySchemeMinSpend?: number | null;

    /** Date for when the discount expires */
    expiry?: Timestamp | Date | null;

};

/** Aggregation of a users spend over X time */
export type TUserSpendAggregation = {

    /** Id of the user */
    key: string;

    /** Number of orders */
    doc_count: number;

    /** Discount percentage */
    totalValue: {
        value: number;
    };

};

/** Aggregation of a users spend over X time */
export type TNewUserDiscountRate = {

    /** Id of the user */
    userId: string;

    /** The new discount rate to be set for the user */
    discountRate: TDiscountRate;

};

/**
 * Basic information for updating a staff user
 */
export type TStaffDetails = TUser & {

    /** Id of staff primary location of work */
    locationId: string;

    /** System roles staff entitled to access */
    roles: TRoles[];

    /** All locations a staff member can view */
    locations: string[];

};
