import { useState, useEffect } from 'react';
import { FirebaseOptions } from '@firebase/app-types';
import axios from 'axios';



export const useFirebaseConfig = () => {

    const [config, setConfig] = useState<FirebaseOptions>();

    /**
     * Get the config object from firebase hosting, or return the default
     */
    useEffect(() => {

        const defaultConfig = {
            apiKey: 'AIzaSyBmKSQlzI0BG0Y6xTsVtHdV-lzvD0kf7iE',
            authDomain: 'fuocos-pizza-dev.firebaseapp.com',
            databaseURL: 'https://fuocos-pizza-dev.firebaseio.com',
            projectId: 'fuocos-pizza-dev',
            storageBucket: 'fuocos-pizza-dev.appspot.com',
            messagingSenderId: '1033200475107',
            appId: '1:1033200475107:web:2e196f1d7d78cab8af5ed6',
            measurementId: 'G-7CZN43KSH6'
        };

        axios.get('/__/firebase/init.json').then(({ data }) => {
            console.debug('setConfig', data);
            setConfig(data);
        }).catch(() => setConfig(defaultConfig));

    }, []);

    return { config };

};
