import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthedNavigation from './authed-navigation';
import RemoteConfig from './remote-config';
import HomePage from './home';
import OpeningSoonPage from './opening-soon';
import CheckoutPage from './checkout';
import { LocationProvider, PostcodeProvider } from '../contexts/LocationContext';
import OrderHistoryPage from './order-history';
import DownloadPage from './download';
import MenuPage from './menu';

export const AppRoutes: React.FC = () => {

    const [navBarHeight, setNavBarHeight] = useState<number>(0);

    const handleGetNavBarHeight = (height: number) => {
        setNavBarHeight(height);
    };

    return (

        <Router>

            {/* Authenticated Routes */}
            <RemoteConfig>
                <AuthedNavigation>
                    <LocationProvider>
                        <PostcodeProvider>
                            <Routes>
                                <Route path='/' element={<HomePage handleGetNavBarHeight={handleGetNavBarHeight} />}>
                                    <Route index element={<MenuPage navBarHeight={navBarHeight} />} />
                                    <Route path='/checkout' element={<CheckoutPage />} />
                                    <Route path='/openingsoon' element={<OpeningSoonPage />} />
                                    <Route path='/orders' element={<OrderHistoryPage />} />
                                    <Route path='/orders/:orderId' element={<OrderHistoryPage />} />
                                    <Route path='/download' element={<DownloadPage />} />
                                </Route>
                            </Routes>
                        </PostcodeProvider>
                    </LocationProvider>
                </AuthedNavigation>
            </RemoteConfig>

        </Router>

    );

};
