import { useFirestore, useFunctions, useFirestoreDocData, useAuth, } from 'reactfire';

import {
    TMenuProduct, TValidateBasketRequestItem, TValidateBasketRequest,
    TBasket, TCheckoutResponse, TPayOptions, TPayResponse, TValidatedBasketItem
} from '@hcstechnologies/fuocos-interfaces/src/types';
import { httpsCallable } from 'firebase/functions';
import { COLLECTION_NAMES, MINIMUM_VERSION } from '@hcstechnologies/fuocos-interfaces/dist/types';
import { DocumentReference, doc } from 'firebase/firestore';
import { noop, round, cloneDeep } from 'lodash';
import { getAnalytics, logEvent } from 'firebase/analytics';

/**
 * Features to manage the basket
 * @returns
 */
export const useBasket = () => {

    const analytics = getAnalytics();
    const functions = useFunctions();
    const auth = useAuth();
    const firestore = useFirestore();

    if (auth.currentUser === null) throw new Error('No currentUser');

    // TODO: Make this typesafe
    console.debug(`get:${COLLECTION_NAMES.BASKETS}/${auth.currentUser.uid}`);
    const docRef = doc(firestore, COLLECTION_NAMES.BASKETS, auth.currentUser.uid) as DocumentReference<TBasket>;

    /** Basket for logged in user */
    const basketDoc = useFirestoreDocData(docRef);

    /** Update item quantity in basket */
    const updateItemQty = async (item: TValidatedBasketItem, index: number,
        array: TValidateBasketRequestItem[], delta: number) => {

        try {

            const newItems: TValidateBasketRequestItem[] = cloneDeep(array);
            const newQty = round(item.quantity + delta);

            if (newQty > 0) {
                newItems.splice(index, 1, {
                    productId: item.productId,
                    productName: item.productName,
                    quantity: newQty,
                    extras: item.extras,
                });
            } else {
                newItems.splice(index, 1);
            }

            const response = await validateBasket(basketDoc.data.locationRef.path, basketDoc.data.userLocation, newItems);

            console.debug(response);

            logEvent(analytics, delta === -1 ? 'remove_from_cart' : 'add_to_cart', {
                currency: 'GBP',
                items: [
                    {
                        item_id: item.productId,
                        item_name: item.productName,
                        index: 0,
                        price: item.unitPrice,
                        quantity: item.quantity
                    }
                ]
            });

        } catch (error) {
            console.error(error);
        }

    };

    /** Validate Basket */
    const validateBasket = async (locationPath: string, userLocation: string, items: TValidateBasketRequestItem[]) => {

        const validateBasketTrigger = httpsCallable<TValidateBasketRequest, TBasket>(functions, 'validateBasket');

        const newBasket: TValidateBasketRequest = {
            locationPath,
            userLocation,
            items,
            originV2: 'web',
            appVersion: MINIMUM_VERSION
        };

        const validatedBasket = await validateBasketTrigger(newBasket);

        logEvent(analytics, 'validate_basket');

        return validatedBasket.data;



    };

    /** Add product to basket and revalidate */
    const addProductToBasket = async (
        product: TMenuProduct,
        quantity: number,
        extras: TValidateBasketRequestItem['extras'],
        extraItems: TValidateBasketRequest['items'],
        remove: TValidateBasketRequestItem['remove'],
        locationPath: string,
        userLocation: string,
        menuId?: string
    ): Promise<TBasket> => {


        if (basketDoc.status !== 'success') throw new Error('Basket is not Loaded');

        const items: TValidateBasketRequestItem[] = (
            basketDoc.data?.items || []
        ).concat({
            productId: product.productId,
            productName: product.name,
            quantity,
            extras,
            remove,
            ...(menuId !== undefined && { menuId })
        }).concat(extraItems.map((item) => (
            {
                productId: item.productId,
                productName: item.productName,
                quantity: item.quantity,
                extras: item.extras,
                remove: item.remove,
                ...(menuId !== undefined && { menuId })
            })
        ));

        return validateBasket(locationPath, userLocation, items);

    };

    /** Call checkout endpoint for basket */
    const checkout = async (): Promise<TCheckoutResponse> => {

        const checkout = httpsCallable<null, TCheckoutResponse>(functions, 'checkout');

        logEvent(analytics, 'checkout');
        const exports = await checkout();

        return exports.data;
    };

    /** Send pay request to the server */
    const pay = async (payRequest: TPayOptions): Promise<TPayResponse> => {

        const payFn = httpsCallable<TPayOptions, TPayResponse>(functions, 'pay');

        logEvent(analytics, 'pay');
        const exports = await payFn(payRequest);

        return exports.data;

    };

    const addDealToBasket = noop;

    const clearBasket = async () => {

        const clearBasket = httpsCallable<void, void>(functions, 'clearBasket');

        await clearBasket();

        basketDoc.data.items.forEach(basketItem => {

            logEvent(analytics, 'remove_from_cart', {
                currency: 'GBP',
                items: [
                    {
                        item_id: basketItem.productId,
                        item_name: basketItem.productName,
                        index: 0,
                        quantity: basketItem.quantity,
                    }
                ]
            });

        });

    };

    return {
        validateBasket,
        updateItemQty,
        addProductToBasket,
        basketDoc,
        addDealToBasket,
        clearBasket,
        checkout,
        pay,
    };
};
