import React from 'react';
import { useRemoteConfigBoolean } from 'reactfire';
import Loading from '../components/loading';
import OpeningSoonPage from './opening-soon';


/**
 * Get remote condif parameters that may affect default routing behaviour
 * @param param0
 * @returns
 */
const RemoteConfig: React.FC<React.PropsWithChildren<any>> = ({ children }) => {

    const openingSoon = useRemoteConfigBoolean('opening_soon');

    if (openingSoon.status === 'loading') return <Loading />;

    if (openingSoon.status === 'error') return <h1>{openingSoon.error}</h1>;

    // Display Opening Soon
    if (openingSoon.data === true) return <OpeningSoonPage />;

    // Display real content
    return children;

};

export default RemoteConfig;
