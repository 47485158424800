import React from 'react';
import { TMenuCategory, TMenuProduct, TMenuSubCategory, RecWithId }
    from '@hcstechnologies/fuocos-interfaces/src/types';
import { Typography, Grid, Box, Container, useTheme, useMediaQuery } from '@mui/material';

export type TMenuCategoryArgs = {

    /** Details of category to display */
    menuCategory: RecWithId<TMenuCategory> | TMenuSubCategory | null;

    /** Component to display for product information */
    productComponent: (product: TMenuProduct) => JSX.Element;

    /** Display header name */
    displayHeader?: boolean;

    /** We want to style a category differently if it is a child of another category */
    isChildCategory?: boolean;

};

/** Display category for menu */
export const MenuCategory: React.FC<TMenuCategoryArgs> = (
    { menuCategory, productComponent, displayHeader = true, isChildCategory }) => {

    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (menuCategory === undefined || menuCategory === null) return <></>;

    return (

        <React.Fragment>

            {displayHeader &&
                <Typography variant='h4' width={isChildCategory ? '100%' : 'auto'} textAlign='center'
                    mt={isChildCategory ? 2 : 0} mb={isChildCategory ? 2 : 0}>
                    {menuCategory.name}
                </Typography>
            }

            {menuCategory.products &&

                <Container maxWidth={isSmall ? 'xs' : isMedium ? 'sm' : 'lg'}>
                    <Grid container direction="row" spacing={isSmall ? 8 : 4}>

                        {menuCategory.products?.map(product => {

                            return (
                                <Grid container justifyContent='center' key={product.productId}
                                    item xs={12} sm={6} md={4} lg={4} xl={4} >
                                    {productComponent(product)}
                                </Grid>
                            );

                        })}

                    </Grid>
                </Container>
            }

            {/* Categories can be nested, display sub categories inline */}
            {
                menuCategory.childCategories?.map(
                    (category, index) => (
                        <Box key={index} width='100%'>
                            <MenuCategory menuCategory={category} productComponent={productComponent}
                                isChildCategory={true}
                            />
                        </Box>
                    )
                )
            }
        </React.Fragment>

    );

};
