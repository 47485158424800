import React, { PropsWithChildren, useRef, useState, useEffect } from 'react';
import { Tabs, Tab, Grid, Stack, Box, useTheme, Typography, Button, useMediaQuery } from '@mui/material';
import { MonoTypography } from '@hcstechnologies/fuocos-themes';
import { Link } from 'react-router-dom';
import AndroidLogo from '../assets/android.png';
import AppleLogo from '../assets/apple.png';

type TMenuCategory = {
    name: string;
    id: string;
};

export type TMenuCategories = PropsWithChildren<{
    categories: Array<TMenuCategory>;
    currentValue: any;
    onSelectCategory: (ev: React.SyntheticEvent, categoryId: string) => void;
    navBarHeight: number;
}>;

export const MenuCategories: React.FC<TMenuCategories> = ({ categories, currentValue, onSelectCategory, navBarHeight, children }) => {

    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const stickyRef = useRef<Element>(null);
    const [isSticky, setIsSticky] = useState(false);

    /**
     * When our stickyRef intersects with the navBar we want to set isSticky to true so that we can apply
     * custom styles on the sticky reg
     */
    useEffect(() => {

        const cachedRef = stickyRef.current,
            observer = new IntersectionObserver(
                ([e]) => setIsSticky(e.intersectionRatio < 1),
                {
                    rootMargin: `-${navBarHeight}px 0px 0px 0px`,
                    threshold: [1],

                }
            );

        if (cachedRef !== null) {
            observer.observe(cachedRef);

            // unmount
            return function () {
                observer.unobserve(cachedRef);
            };
        }

    }, []);

    return (

        <Grid container direction='column' flexWrap={'nowrap'}>


            {/* TODO: Move contents of box to it's own component */}
            <Box display='flex' flexDirection='column' alignItems='center'
                bgcolor={'primary.main'} color={'primary.contrastText'}>

                <Stack maxWidth='40rem' padding={theme.spacing(4)}>

                    <Typography fontWeight='bold' variant='h5' textAlign={'center'} mb={theme.spacing(4)}>
                        Give me pizza!
                    </Typography>

                    <MonoTypography fontWeight='bold' variant='body1' textAlign={'center'}>
                        You won&apos;t find us on any other delivery apps or websites because only we can guarantee to make and deliver our restaurant quality Neapolitan pizzas just the way they should be. Download the Fuoco&apos;s app to enjoy the hottest pizza in town today.
                    </MonoTypography>

                    <Stack direction='row' justifyContent='center' mt={theme.spacing(4)}>

                        <Link to='/download'>
                            <Button><img src={AppleLogo} height={30} width={30} /></Button>
                        </Link>

                        <Link to='/download'>
                            <Button><img src={AndroidLogo} height={30} width={30} /></Button>
                        </Link>

                    </Stack>

                </Stack>

            </Box>

            {/* Tabs have a sticky prop to prevent scrolling past top of screen */}
            <Box ref={stickyRef} display='flex' justifyContent='center' position={'sticky'} top={0} bgcolor={theme.palette.background.default} zIndex={999} sx={{
                boxShadow: isSticky ? '0.35rem 0.15rem 0.4rem rgba(0, 0, 0, 0.5)' :
                    'none'
            }} >

                <Tabs variant='scrollable' allowScrollButtonsMobile value={currentValue}
                    onChange={onSelectCategory} scrollButtons={'auto'}
                    sx={{
                        margin: 'auto',
                        mt: theme.spacing(2),
                        mb: theme.spacing(1),
                        textColor: theme.palette.text.secondary,
                        maxWidth: '90vw'
                    }}
                    TabIndicatorProps={{ sx: { backgroundColor: theme.palette.text.secondary } }}>

                    {
                        categories.map(category => {
                            return <Tab key={category.id} label={category.name} id={category.id}
                                value={category.id}
                                sx={{
                                    fontSize: isMedium ? theme.spacing(3) : theme.spacing(4),
                                    fontWeight: 'bold', color: theme.palette.text.secondary,
                                    '&.Mui-selected': {
                                        color: theme.palette.text.secondary
                                    },
                                }} />;
                        })
                    }
                </Tabs>

            </Box>

            <Grid item xs>
                {children}
            </Grid>

        </Grid>

    );

};
