import React from 'react';
import { Grid, Typography } from '@mui/material';
import qrCode from '../assets/qr-code.png';
import logo from '../assets/logo-rough-dough.png';

const OpeningSoonPage: React.FC = () => {

    const imageStyle: React.CSSProperties = {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center'
    };

    return (

        <Grid container direction='column' textAlign='center' bgcolor='secondary.main' height={'100vh'} width={'100vw'}>

            <Grid item xs={7} style={{ background: `url(${logo})`, ...imageStyle }} />

            <Grid item xs>
                <Typography fontSize={'4vh'} variant='h1' color='secondary.contrastText'>
                    Opening Soon
                </Typography>
            </Grid>

            <Grid item xs={3} style={{ background: `url(${qrCode})`, ...imageStyle }} />

            <Grid item height='50px' />

        </Grid>

    );

};

export default OpeningSoonPage;
